export const callAPIConstants = {
    AdminLogin: 'admin/login',
    AdminRegister: 'admin/register',
    VerifyOTP: 'admin/verifyOTP',
    SendOTP: 'admin/sendOTP',
    ForgotPassword: 'admin/forgotPassword',
    ResetPassword: 'admin/resetPassword',
    AdminLogout: 'admin/logout',
    AdminGetProfile: 'admin/profile',
    AdminEditProfile: 'admin/editProfile',
    AdminFileUpload: 'admin/fileUpload',
    AdminChangePassword: 'admin/changePassword',
    UpdateTheme: 'admin/updateThemeDetails',

    Dashboard: 'dashboard-stats',
    EarningTrend: 'earning-trend',

    IndustryListing: 'listIndustry',
    changeIndustryStatus: 'changeIndustryStatus',
    deleteIndustries: 'deleteIndustry',
    getIndustryDetails: 'detailIndustry',
    addIndustry: 'addIndustry',
    updateIndustry: 'updateIndustry',
    downloadIndustryFile: 'industry/export',
    importIndustryFile: 'industry/importFile',
    SAMPLEINDUSTRYFILE: '/industry/sampleImportFile',

    getSubIndustryDetails: 'detailSubIndustry',
    addSubIndustry: 'addSubIndustry',
    updateSubIndustry: 'updateSubIndustry',
    SubIndustryList: 'listSubIndustry',
    deleteSubIndustires: 'deleteSubIndustry',
    changeSubIndustryStatus: 'changeSubIndustryStatus',
    downloadSubIndustryFile: 'subIndustry/export',
    importSubIndustryFile: 'subIndustry/importFile',
    SAMPLESUBINDUSTRYFILE: 'subIndustry/sampleImportFile',

    addSkill: 'addSkill',
    updateSkill: 'updateSkill',
    skillsListing: 'listSkills',
    changeSkillStatus: 'changeSkillStatus',
    deleteSkill: 'deleteSkills',
    getSkillDetails: 'detailSkill',
    downloadSkillFile: 'skills/export',
    importSkillFile: 'skills/importFile',
    SAMPLESKILLFILE: 'skills/sampleImportFile',

    addLanguage: 'addLanguage',
    updateLanguage: 'updateLanguage',
    languageListing: 'listLanguages',
    changeLanguageStatus: 'changeLanguageStatus',
    deleteLanguage: 'deleteLanguages',
    getLanguageDetails: 'detailLanguage',
    downloadLanguageFile: 'language/export',
    importLanguageFile: 'language/importFile',
    SAMPLELANGUAGEFILE: 'language/sampleImportFile',

    //social media sdk
    getsocialMediaSDK: 'getSocialMediaSDK',
    addSocialMediaSDK: 'addSocialMediaSDK',
    //social media link
    getSocialMediaLinks: 'getSocialMediaLinks',
    addSocialMediaLinks: 'addSocialMediaLinks',
    //global setiing 
    addGlobalSettings: 'addGlobalSettings',
    getGlobalSettings: 'getGlobalSettings',
    //payment setting
    getPaymentDetails: "getPaymentDetails",
    addPaymentDetails: "/addPaymentDetails",
    //smtp setting
    addSMTPAndSMSSettings: "addSMTPAndSMSSettings",
    getSMTPAndSMSSettings: "getSMTPAndSMSSettings",
    //email notification
    getEmailNotificationSettings: "getEmailNotificationSettings",
    addEmailNotificationSettings: "addEmailNotificationSettings",

    // email-template
    EmailTemplateList: 'listEmail',
    EmailTemplateDetail: 'detailEmail/',
    EmailColumnData: 'getEmailColumnValues',
    // EmailColumnVisibleSettings: 'emailColumnSettings',
    DeleteEmailTemplate: 'deleteTemplate',
    EmailAddNewEmail: 'addUpdateEmail',
    EmailChangeStatus: 'changeTemplateStatus',
    SearchEmail: 'searchEmail',

    //Faq
    FaqsListing: 'faqsListing',
    DeleteFaqs: 'deleteFAQs',
    FaqsDetails: 'getFAQDetails/',
    FaqsAddorUpdate: 'addUpdateFAQ',
    FaqsCategoryListing: 'faqCategoriesListing',
    FaqsStatusChange: 'changeFAQsStatus',
    FaqsSearchList: 'getFAQsList',
    AddUpdateFaqsCategory: 'addUpdateFAQCategory',
    DeleteFaqsCategory: 'deleteFAQCategories',
    FaqsCategoryDetails: 'getFAQCategoryDetails/',
    FaqsCategoryStatus: 'changeFAQCategoriesStatus',
    FaqsCategoriesSearchList: 'getFAQCategoriesList',


    // static && Blogs
    Listing: 'listBlog',
    DeleteBlog: 'deleteBlog',
    ChangeStatus: 'changeBlogStatus',
    GetBlogDetails: 'getBlogDetails/',
    AddBlog: 'addUpdateBlog',
    BlogCategoryListing: 'listBlogCategory',

    AddUpdateBlogCategory: 'addUpdateblogCategory',
    GetBlogCategoryDetails: 'getBlogCategoryDetails/',
    BlogCategoryStatusChange: 'changeBlogCategoryStatus',
    DeleteBlogCategory: 'deleteBlogCategory',
    // user
    UserList: 'user/userListing',
    UserProfile: 'user/userProfile',
    downloadUserFile: 'user/export',
    UserDeleteUsers: 'user/deleteUsers',
    UserChangeStatus: 'user/changeStatus',
    addUser: 'user/addUserByAdmin',
    updateUser: 'user/updateUserByAdmin',
    USERFORGOTPASSWORD: 'user/forgotPassword',
    USERRESETPASSWORD: 'user/resetPassword',

    UserFileUpload: 'users/fileUpload',
    GetUserDetails: 'user/userProfile/',
    UserEXCELCSVforDataTable: 'user/export',
    UserEXCELforDataTable: 'user/downloadExcel',
    UserColumnVisibleSettings: 'user/columnSettings',
    CommonColumnVisibleSettings: 'saveColumnSettings',
    SaveDownloadTemplate: 'saveTemplateSettings',
    getDownloadTemplate: 'getTemplateSettings',
    deleteDownloadTemplate: 'deleteTemplateSettings/',
    UserGetColumnData: 'user/getColumnValues',
    UserGetFilters: 'user/getFilters',
    GetUserPages: 'user/pages',
    GetUserVideos: 'user/videos',
    RoleList: 'listRole',
    GetRoleDetail: 'getRole/',
    AddRole: 'addRole',
    UpdateRole: 'updateRole',
    RoleChangeStatus: 'changeRoleStatus',
    RoleGetColumnData: 'roleFieldsList',
    RoleGetaddedByColumnData: 'adminListInRoles',
    deleteRole: 'deleteRoles',
    RoleListDropDown: 'listRoleDropDown',

    // common
    deleteFilter: 'deleteFilter/',
    saveFilter: 'saveFilter',

    // Sub Admin User
    SubUserList: 'admin/userListing',
    subUserChangeStatus: 'admin/changeStatus',
    subUserDelete: 'admin/deleteUsers',
    getSubUser: 'admin/userProfile/',
    addSubUser: 'admin/addAdminUser',
    updateSubUser: 'admin/updateUser',

    // cms
    cmsListing: 'cmsList',
    cmsAdd: 'addCMS',
    cmsDelete: 'cmsDelete',
    cmsGet: 'cmsDetail/',

    PagesList: 'page/pageListing',
    PageChangeStatus: 'page/changeStatus',
    GetPageDetail: 'page/getPageDetails',
    GetTeamMembers: 'page/teamMembers',
    GetFoundTeamMembers: 'page/foundingTeamMembers',
    GetInvestors: 'page/startUpInvestors',
    GetPortfolio: 'page/portfolios',
    GetPackages: 'page/packages',
    PAGEUSERS: 'user/searchUsers',
    getDocuments: 'page/getDocuments/',
    GetFeedVideos: 'feed/reportedVideoListing',
    VideoDetails: 'feed/reportedVideoDetail',
    changeVideoStatus: 'feed/changeVideoStatus',

    GetFeedPages: 'page/reportedPageListing',
    PageDetails: 'page/reportedPageDetail',

    commentChangeStatus: 'feed/changeCommentStatus',
    commentListing: 'feed/reportedCommentListing',
    commentDetail: 'feed/reportedCommentDetail',

    IdentityRequest: 'verificationManagement/identityVerificationRequestListing',
    ReportIdentityRequest: 'verificationManagement/reviewIdentityRequest',
    IdentityRequestDetail: 'verificationManagement/identityRequestDetail/',
    DeleteIdentityRequest: 'verificationManagement/removeIdentityRequests',
    DeleteAccrRequest: 'verificationManagement/removeAccreditationRequests',

    AccreditationRequest: 'verificationManagement/accreditationVerificationRequestListing',
    ReportAccreditationRequest: 'verificationManagement/reviewAccreditationRequest',
    AccreditationRequestDetail: 'verificationManagement/accreditationRequestDetail/',

    packageListing: 'subscriptionManagement/subscriptionList',
    packageChangeStatus: 'subscriptionManagement/changeStatus',
    packageUpdate: 'subscriptionManagement/updatePackage',

    paymentTrnListing: 'transactionManagement/transactionList',
    downloadTrnListing: 'transactionManagement/exportTransactions',
    creditTrnListing: 'transactionManagement/creditTransactionList',
    downloadCreditListing: 'transactionManagement/exportCreditTransactions',

    disputeRequestListing: 'job-disputes',
    job: 'job',
    identities: 'identities'
};

