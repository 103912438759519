import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestrictKeyPressService } from 'src/app/common/service/restrict-key-press.service';
import { ThemeService } from 'src/app/common/service/theme/theme.service';
import { callAPIConstants } from '../../common/constants/callAPI-constants';
import { URLConstants } from '../../common/constants/routerLink-constants';
import { CommonService } from '../../common/service/common.service';
import { LocalStorageService } from '../../common/service/local-storage.service';
import { ShowErrorService } from '../../common/service/show-error.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: []
})
export class ForgotPasswordComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  public isSettingOpen = false;
  public errorMsg: string = "";
  public forgotPassword: any = {};
  public showMessage: boolean;
  public emailId: any;


  constructor(private themeService: ThemeService,
    private router: Router, public restrictKeyPressService: RestrictKeyPressService, private localStorageService: LocalStorageService, private commonService: CommonService, private showErrorService: ShowErrorService
  ) { }

  ngOnInit(): void {
    var isDark = this.localStorageService.getToken('theme') == "Dark" ? true : false;
    this.themeService.switchThemeAfterLogin(isDark);
    if (isDark)
      document.getElementById("forgotPassSwitchTheme").setAttribute('checked', '');
  }

  onForgotPassword(form) {
    if (form.value.email !== null && form.value.email !== "") {
      this.forgotPassword = {
        emailId: form.value.email
      }
      this.commonService.callApi(this.callAPIConstants.ForgotPassword, this.forgotPassword, 'post', true, false).then((success) => {
        if (success.status === 1) {
          this.showErrorService.displayToastr('success', success.message);
          this.showMessage = true;
        } else {
          this.showErrorService.displayToastr('error', success.message);
        }
      });
    }
  }

  openSettingBox() {
    this.isSettingOpen = !this.isSettingOpen;
  }

  toggleTheme() {
    var theme = this.themeService.switchTheme();
    this.localStorageService.setToken('theme', theme);
  }
}
