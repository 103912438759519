// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --configuration='staging' && node server.js` replaces `environment.ts` with `environment.staging.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  environmentName: 'staging',
  port: 4042,
  apiUrl: 'https://unicornapi.inic.cloud/admin/',
  uploadImgUrl: 'https://d34gjrzkm20pbx.cloudfront.net/staging/',
  downloadUrl: 'https://unicornapi.inic.cloud/admin/public/',
  pdfUrl: 'https://d34gjrzkm20pbx.cloudfront.net/staging/',
};