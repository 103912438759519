import { RestrictKeyPressService } from './../../../../common/service/restrict-key-press.service';
import { ShowErrorService } from './../../../../common/service/show-error.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { CommonService } from 'src/app/common/service/common.service';
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';

@Component({
  selector: 'app-mark-dispute',
  templateUrl: './mark-dispute.component.html',
  styleUrls: []
})
export class MarkDisputeComponent implements OnInit {

  apiConstant = callAPIConstants;

  isResolved = false;
  id;
  data: any = {};
  submitted = false;

  public event: EventEmitter<any> = new EventEmitter();

  constructor(
    public modalRef: BsModalRef,
    public showErrorService: ShowErrorService,
    private commonService: CommonService,
    public restrictKeyPressService: RestrictKeyPressService
  ) { }

  ngOnInit(): void {
  }

  public noWhitespaceValidator(value) {
    const re = new RegExp(/^(\s+\S+\s*)*(?!\s).*$/);
    return re.test(value);
  }
  
  submit(form) {
    if (form.valid && this.noWhitespaceValidator(this.data.comment)) {
      if (this.isResolved) {
        this.markAsResolved()
      } else {
        this.addComment()
      }
    } else {
      this.submitted = true;
    }
  }

  markAsResolved() {
    if ((Number(this.data.consumerPercent) > 100) || (Number(this.data.providerPercent) > 100) || ((Number(this.data.consumerPercent) +  Number(this.data.providerPercent)) != 100)) {
      this.showErrorService.popToast('error', 'Percentage sum should not be greater than 100')
    } else {
      const params = {
        comment: this.data.comment,
        consumerPercent: Number(this.data.consumerPercent),
        providerPercent: Number(this.data.providerPercent)
      };
      this.commonService.callApi(this.apiConstant.disputeRequestListing + '/' + this.id + '/resolve' , params, 'patch', false).then((success) => {
        if (success) {
          this.modalRef.hide();
          this.event.emit();
        }
      })
    }
  }

  addComment() {
    const params = {
      comment: this.data.comment,
      disputeId: this.id
    };
    this.commonService.callApi(this.apiConstant.disputeRequestListing + '/' + this.id + '/add-comment' , params, 'post', false).then((success) => {
      if (success) {
        this.modalRef.hide();
        this.event.emit();
      }
    })
  }

  calcPer() {
    if ((Number(this.data.consumerPercent) > 100) || (Number(this.data.providerPercent) > 100)) {
    } else {
      if (this.data.consumerPercent) {this.data.providerPercent = 100 - Number(this.data.consumerPercent)}
      else if (this.data.providerPercent) {this.data.consumerPercent = 100 - Number(this.data.providerPercent)}
    }
  }

}
