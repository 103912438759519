<div class="theme-setting-block" [ngClass]="{'open': isSettingOpen}">
    <a class="theme-setting-link" (click)="openSettingBox()">
        <em class="bx bx-cog bx-flip-horizontal" [ngClass]="{'bx-spin': !isSettingOpen}"></em>
    </a>
    <div class="d-flex align-items-center">
        <span class="light-icon icon mr-1 d-block"><em class="bx bx-sun"></em></span>
        <div class="custom-control custom-switch theme-switch">
            <input type="checkbox" class="custom-control-input" id="forgotPassSwitchTheme" (click)="toggleTheme()">
            <label class="custom-control-label" for="forgotPassSwitchTheme"></label>
        </div>
        <span class="dark-icon icon"><em class="bx bxs-sun"></em></span>
    </div>
</div>
<!--Start Login Wrapper-->
<div class="login-wrapper"
    style="background:url(assets/images/login-bg.jpg) no-repeat center center; background-size: cover;">
    <div class="login-body">
        <section class="login-container row m-0">
            <div class="col-xl-8 col-11">
                <div class="card bg-login-card" [hidden]="showMessage">
                    <div class="row m-0">
                        <div class="col-md-6 col-12 pl-0 pr-0">
                            <div class="card rounded-left mb-0 p-4 d-flex justify-content-center h-100">
                                <div class="card-header">
                                    <div class="card-title text-center mb-0">
                                        <img src="assets/images/logo.png" style="margin-bottom:20px;" alt="GitFunded"
                                            title="GitFunded" width="220">
                                        <h4 class="mb-2 mt-2">Super Admin</h4>
                                    </div>
                                </div>
                                <div class="card-title text-center mb-4">
                                    <h4 class="mb-2 mt-2">Forgot Password?</h4>
                                </div>
                                <div class="text-center mb-3">
                                    <p><small class="d-block common-small-text">Enter your email address
                                            below</small></p>
                                </div>
                                <div class="card-content">
                                    <div class="card-body">
                                        <form (ngSubmit)="forgotPassForm.form.valid && onForgotPassword(forgotPassForm)"
                                            #forgotPassForm="ngForm">
                                            <div class="form-group mb-4">
                                                <label>Your Email<sup class="bx bxs-star danger"></sup></label>
                                                <input ngModel type="text" name="email" id="EmailAddress"
                                                    aria-describedby="emailHelp" placeholder="Enter Email"
                                                    pattern="^\w+([\.\-_+]?\w+)*@\w+([\.\-_+]?\w+)*(\.\w{2,10})+$"
                                                    class="form-control" #email="ngModel"
                                                    (keydown)="restrictKeyPressService.RestrictSpace($event)"
                                                    [ngClass]="{ 'is-invalid':(forgotPassForm.submitted || forgotPassForm.touched) && email.invalid  }"
                                                    required email>
                                                <div *ngIf="(forgotPassForm.submitted || forgotPassForm.touched) && email.invalid"
                                                    class="invalid-feedback">
                                                    <div *ngIf="email.errors.required">Please enter your email address

                                                    </div>
                                                    <div *ngIf="email.errors.pattern">Please enter valid email</div>
                                                </div>

                                            </div>
                                            <div class="col-md-12 row">
                                                <div class="col-md-5">
                                                    <button class="btn btn-secondary glow position-relative btn-block"
                                                        [routerLink]="[URLConstants.LOGIN]">
                                                        Cancel
                                                    </button>
                                                </div>
                                                <div class="col-md-5" style="margin-left:25px">
                                                    <button class="btn btn-primary glow position-relative btn-block">
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 d-md-block d-none text-center align-self-center p-7">
                            <div class="card-content">
                                <picture>
                                    <source srcset="assets/images/forgot-password.webp" type="image/webp">
                                    <source srcset="assets/images/forgot-password.png" type="image/png">
                                    <img class="img-fluid" src="assets/images/forgot-password.png" alt="forgot-password"
                                        width="300">
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card bg-login-card" [hidden]="!showMessage">
                    <div class="card-header pt-5">
                        <div class="card-title text-center mb-4">
                            <img src="assets/images/logo.png" style="margin-bottom:20px;" width="220" alt="GitFunded">
                            <h4 class="mb-2 mt-2">Super Admin</h4>
                        </div>
                    </div>
                    <div class="card-content">
                        <div class="card-body">
                            <h3 class="pb-3 pt-5 text-center">Email sent successfully</h3>
                            <p class="pb-2 text-center">We have sent a reset password link to your
                                email. Please open email and click on reset password link to set a new
                                password.
                            </p>
                            <div class="col-md-12 text-center">
                                <button class="btn btn-primary glow position-relative btn-lg"
                                    [routerLink]="[URLConstants.LOGIN]">
                                    Sign In
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
<!--End Login Wrapper-->