<div class="modal-header" [ngClass]="(type != 'feedpage') ? 'white-icon': 'none'">
    <button type="button" class="close pull-right" aria-label="Close" (click)="changedesc()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body page-details-modal video-popup-wrap"
    [ngStyle]="{'margin-top' : (type == 'feedpage') ? '0px':'none'}">
    <div class="col-md-12" [ngStyle]="{'padding-bottom' : (type == 'feedpage') ? '135px':'none'}">
        <div class="row">
            <div class="user-info d-flex align-items-center">
                <div class="video-image" *ngIf="type=='feedvideo'">
                    <img class="img-fluid" alt="image"
                        [src]="(data.logo) ? imgUrl + (data.logo) :  'assets/images/NoProfile.png'"
                        (error)="img1.src = 'assets/images/NoProfile.png'" width="100" height="100" #img1>
                </div>
                <div class="video-image" *ngIf="type=='feedpage'">
                    <img class="img-fluid" alt="image"
                        [src]="(data.createdBy?.photo) ? imgUrl + (data.createdBy?.photo) :  'assets/images/NoProfile.png'"
                        (error)="img1.src = 'assets/images/NoProfile.png'" width="100" height="100" #img1>
                </div>
                <div class="video-image" *ngIf="type=='feedcomment'">
                    <img class="img-fluid" alt="image"
                        [src]="(video.identityInfo?.photo) ? imgUrl + (video.identityInfo?.photo) :  'assets/images/NoProfile.png'"
                        (error)="img1.src = 'assets/images/NoProfile.png'" width="100" height="100" #img1>
                </div>
                <div class="user-name">
                    <!-- <p [ngClass]="((type == 'feedvideo') || (type == 'feedcomment')) ? 'name-color' : 'name-color-sub'">
                        {{data.name}}</p> -->
                    <p [ngClass]="((type == 'feedvideo') || (type == 'feedcomment')) ? 'name-color' : 'name-color-sub'">
                        {{data.identityName}}</p>
                    <p *ngIf="(type == 'feedcomment')" class="name-color">
                        {{video.identityInfo?.name}}</p>
                    <p *ngIf="(type == 'feedvideo') || (type == 'feedpage')"
                        [ngClass]="((type == 'feedvideo')) ? 'name-color' : 'name-color-sub'">
                        {{data.createdBy?.firstName}} {{data.createdBy?.lastName}}</p>
                    <p class="sub-name" *ngIf="(type == 'feedvideo' || type == 'feedpage')"
                        [ngStyle]="{'color':(type == 'feedvideo') ? 'white' : 'none'}">Post
                        reported {{count}} {{(count == 1) ? 'time' : 'times'}}</p>
                    <p class="sub-name" *ngIf="(type == 'feedcomment')"
                        [ngStyle]="{'color':(type == 'feedcomment') ? 'white' : 'none'}">Post
                        reported {{videoDetail.reportsCount}} {{(videoDetail.reportsCount == 1) ? 'time' : 'times'}}</p>
                </div>
            </div>
        </div>
        <vg-player *ngIf="(type == 'feedvideo') || (type == 'feedcomment')">
            <vg-controls [vgAutohide]="true" [vgAutohideTime]="1">
                <vg-play-pause></vg-play-pause>
                <vg-scrub-bar [vgSlider]="true" class="d-flex">
                    <vg-scrub-bar-current-time [vgSlider]="true">
                    </vg-scrub-bar-current-time>
                    <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
                </vg-scrub-bar>

                <div class="custom-play-pause-time d-flex">
                    <vg-time-display vgProperty="current" vgFormat="mm:ss">
                    </vg-time-display>
                    <span
                        style="width: 1px;background-color: #ffffff;height: 13px;margin-top: 18px;transform: rotate(10deg)"></span>
                    <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
                </div>
                <div class="custom-controls-icons d-flex align-items-center">
                    <vg-mute></vg-mute>
                    <span class="icon-maximize" (click)="viewPictureInPicture()"></span>
                    <vg-fullscreen (click)="addFullScreen()"></vg-fullscreen>
                </div>
            </vg-controls>
            <vg-overlay-play [vgFor]="'videofeed'"></vg-overlay-play>
            <vg-buffering [vgFor]="'videofeed'"></vg-buffering>
            <video *ngIf="(type=='feedvideo')" [vgMedia]="videoPlayer" #videoPlayer [id]="'videofeed'"
                class="video-feed" preload="auto"
                [src]="(data.videoPath ? data.videoPath : data.path) ? (imgUrl + (data.videoPath ? data.videoPath : data.path)) : '../../../../assets/video/SampleVideo_770x460.mp4'">
            </video>
            <video *ngIf="(type=='feedcomment')" [vgMedia]="videoPlayer" #videoPlayer [id]="'videofeed'"
                class="video-feed" preload="auto"
                [src]="((video.videoPath) ?(video.videoPath) : data.path) ? (imgUrl + ((video.videoPath) ? (video.videoPath) : data.path)) : '../../../../assets/video/SampleVideo_770x460.mp4'">
            </video>
            <div class="ml-sm-auto like-dislike" *ngIf="(type != 'feedpage')"><button type="button" class="btn"><span
                        class="bx bxs-like pr-1"></span>{{like}} {{((like == 0 || like == 1)) ? 'Like' : 'Likes'}}
                </button><button type="button" class="btn"><span class="bx bxs-comment pr-1"></span>{{comment}}
                    {{((comment == 0
                    || comment == 1)) ? 'Comment' : 'Comments'}}
                </button></div>
        </vg-player>
    </div>
    <div class="text-right p-2 col-md-12" *ngIf="type == 'feedvideo'">
        <button type="button" class="btn btn-primary" (click)="closeHide(data)"><span
                [ngClass]="(this.activestatus) ? 'bx bx-show' :'bx bx-hide pr-2'"></span>
            Hide</button>
    </div>
    <div class="user-info-bottom col-md-12" *ngIf="type == 'feedcomment'">
        <div class="d-flex align-items-center profile-top-bar">
            <div class="video-image">
                <img class="img-fluid" alt="image"
                    [src]="(data.photo) ? imgUrl + (data.photo) :  'assets/images/NoProfile.png'"
                    (error)="img1.src = 'assets/images/NoProfile.png'" width="100" height="100" #img1>
            </div>
            <p class="gray-text">{{videoDetail.commentDetail?.comment}}</p>
        </div>
        <div class="d-flex align-items-center dropdown-wrap  mb-2">
            <h6>{{data.name}}</h6>
            <div>
                <ng-select [items]="statusListData" placeholder="Select" bindLabel="name" bindValue="value"
                    [clearable]='false' [searchable]='false' [dropdownPosition]="'auto'" #status="ngModel" name="status"
                    [(ngModel)]="videoDes.status" (change)="statusChange(videoDes.status)">
                </ng-select>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <app-responsive-data-table [tableSetupData]="tableSetupData" [accessPermission]="accessPermission"
            #responsiveDataTable>
        </app-responsive-data-table>
    </div>
</div>