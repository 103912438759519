import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
interface VideoPlayer extends HTMLVideoElement {
  requestPictureInPicture(): any;
}
@Component({
  selector: 'app-view-videos',
  templateUrl: './view-videos.component.html',
  styleUrls: []
})
export class ViewVideosComponent implements OnInit {

  imgUrl = environment.uploadImgUrl;
  data;
  type;
  isIntro = false;
  @ViewChild('videoPlayer') videoPlayer: ElementRef;

  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
  }

  /*********************************************************
  @PURPOSE: view video in Picture-in-Picture mode
  *********************************************************/
  viewPictureInPicture() {
    const video: VideoPlayer = this.videoPlayer.nativeElement;
    this.bsModalRef.hide();
    video.requestPictureInPicture();
  }
  /*********************************************************/

  /*********************************************************
  @PURPOSE: add class for full screen
  *********************************************************/
  addFullScreen() {
    const video = document.getElementById('videoblock').classList;
    video.add('fullscreen');
    this.exitEvent();
  }

  /*********************************************************
  @PURPOSE: remove class on full screen exit
  *********************************************************/
  exitEvent() {
    document.addEventListener('fullscreenchange', exitHandler);
    document.addEventListener('webkitfullscreenchange', exitHandler);
    document.addEventListener('mozfullscreenchange', exitHandler);
    document.addEventListener('MSFullscreenChange', exitHandler);

    function exitHandler() {
      if (!document['webkitIsFullScreen'] && !document['mozFullScreen'] && !document['msFullscreenElement']) {
        const video = document.getElementById('videoblock').classList;
        video.remove('fullscreen');
      }
    }
  }
}
