import { creditSubType, Role_Category } from './../../../common/constants/routerLink-constants';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, NgZone, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

// environment
import { environment } from 'src/environments/environment';

// plugins
import { DataTableDirective } from 'angular-datatables';
import Responsive from 'datatables.net-responsive';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';

// constants
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';

// Services
import { CommonService } from '../../../common/service/common.service';
import { RestrictKeyPressService } from '../../../common/service/restrict-key-press.service';
import { ShowErrorService } from '../../../common/service/show-error.service';
import * as moment from 'moment';
// interfaces
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { BroadcasterService } from 'src/app/common/service/broadcaster.service';
import { TemplateDetailsComponent } from '../modals/template-details/template-details.component';
import { CommonModalComponent } from '../modals/common-modal/common-modal.component';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { ReviewCommentComponent } from '../modals/review-comment/review-comment.component';
import { AccreditationRequestComponent } from '../modals/accreditation-request/accreditation-request.component';
import { VideoDescriptionComponent } from '../modals/video-description/video-description.component';
import { env } from 'process';
import { ViewVideosComponent } from '../modals/view-videos/view-videos.component';


@Component({
  selector: 'app-responsive-data-table',
  templateUrl: './responsive-data-table.component.html',
  styles: [],
  providers: [{ provide: BsDropdownConfig, useValue: { autoClose: true } }],

})
export class ResponsiveDataTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('filterForm', { static: false }) filterForm;
  @ViewChild('descriptionForm', { static: false }) descriptionForm;

  callAPIConstants = callAPIConstants;
  public URLConstants = URLConstants;
  public uploadImageUrl = environment.uploadImgUrl;
  public downloadUrl = environment.downloadUrl;
  public pdfUrl = environment.pdfUrl

  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dropdown: boolean = false;
  APIparameters: any = {};
  options: any = {};
  total: any;
  public type: any;
  public submitted = false;
  public selectedUserList: any[] = [];
  public columnVisibilityOptions = false;
  public filterColumnOption = false;
  public params: any;
  public importFile: any;
  public sampleimportFile: any;
  public conditions: any;
  public searchText: any;
  public color: any = '#39DA8A';
  public filterColor: any = '#39DA8A';
  public tempSettingsData: any = [];
  showSkeletonLoader1: boolean = true;
  @Input() accessPermission: any;
  @Input() roleAccessPermission: any;
  @Input() columns: Array<any>;
  @Input() tableSetupData: any;
  @Input() subscriptionList: any;
  @Input() roleList: any;
  public tempData: any = [];
  public tableConfig: any = {};
  tableData = {
    cols: ['id', 'author', 'title'],
    data: [],
    rowSelected: 0,
    value: false,
    type: '',
  };
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  @ViewChild('customPagination', { static: false }) customPagination: any;
  @ViewChild('filterToggle') filterToggle: ElementRef;
  @ViewChild('downloadFile') downloadFile: ElementRef;
  public downloadColumnsArr: any = [];
  public columnVisibilityOptions1: any;
  public columnVisibilityOptions2: boolean = false;
  public columnKey: any;
  public columnsAction: any = false;
  downloadColumns: string;
  toggleDownloadButton: boolean;
  toggleFilterButton: boolean = false;
  getDowloadTemplates: string;
  downloadTemplatesArr: any = [];
  downloadUserFile: string;
  link: string;
  cols: any = [];
  configs: any = {};
  public colSettingCols: Array<any> = [];
  listingAddUpdateBroadCaster: any;
  calenderData: any = {};
  status: boolean = false;
  statusDownload: boolean = false;
  statusColorPicker: boolean = false;
  statusFilterColorPicker: boolean = false;
  identityId;
  colors = [
    {
      hex: '#00cfdd',
      name: 'blue'
    },
    {
      hex: '#b3c0ce',
      name: 'gray'
    },
    {
      hex: '#FF5B5C',
      name: 'red'
    },
    {
      hex: '#b87ee5',
      name: 'purple'
    },
    {
      hex: '#39DA8A',
      name: 'green'
    },
    {
      hex: '#FDAC41',
      name: 'yellow'
    },
  ];
  public statusListData: any = [
    { name: 'Active', value: 'Active' },
    { name: 'InActive', value: 'Inactive' }
  ];
  public creatorListData: any = [
    { name: 'Admin', value: 'Admin' },
    { name: 'Sub Admin', value: 'Sub admin' },
    { name: 'Users', value: 'Users' }
  ]
  selectedColor: '';
  public downloadCheckbox: boolean = false;
  public roleCategory = Role_Category;
  public creditSubType = creditSubType;
  public today = new Date();

  selectColor(hash, col) {
    this.color = col;
    document.getElementById("downloadDropdownColor").style.backgroundColor = hash;
    this.openCloseColorDropdown();
  }

  selectFilterColor(hash, col) {
    this.filterColor = col;
    document.getElementById("filterDropdownColor").style.backgroundColor = hash;
    this.openCloseColorFilterDropdown();

  }

  active: boolean = false;
  selectedColorName: '';
  selector() {
    if (!this.selectedColor) {
      return 'Color';
    }
    else {
      return '<span style="background: ' + this.selectedColor + '"></span> ' + this.selectedColorName;
    }
  }

  setColor(color, colorName) {
    this.selectedColor = color;
    this.selectedColorName = colorName;
    this.active = false;
  }


  toggleDropdown() {
    this.active = !this.active;

  }

  // open filter button
  openFilter() {
    this.status = !this.status;
    this.columnVisibilityOptions = false;
  }

  // open download button
  openDownloadButton() {
    this.statusDownload = !this.statusDownload;
    this.filterColumnOption = false;
  }

  // open color dropdown
  openCloseColorDropdown() {
    this.statusColorPicker = !this.statusColorPicker;
  }

  // open color filter dropdown
  openColorDropdownFilter() {
    this.statusFilterColorPicker = !this.statusFilterColorPicker
  }

  // open color filter dropdown
  openCloseColorFilterDropdown() {
    this.statusFilterColorPicker = !this.statusFilterColorPicker
  }
  // filter related variables
  typeArr = [
    { name: 'Contains', value: 'contains' },
    { name: 'Date', value: 'date' },
    { name: 'Equal', value: 'equal' },
    { name: 'Greater Than', value: 'greaterThan' },
    { name: 'Less Than', value: 'lessThan' },
  ];
  pageTypeArr = [
    { name: 'Startup', value: 'Startup' },
    { name: 'Inv-Individual', value: 'Investor - individual' },
    { name: 'Inv-Company', value: 'Investor - company' },
    { name: 'SP-Individual', value: 'Service Provider - individual' },
    { name: 'SP-Company', value: 'Service Provider - company' }
  ];
  userPageTypeArr = [
    { name: 'Investor', value: 'investor' },
    { name: 'Startup', value: 'startup' },
    { name: 'Service Provider', value: 'serviceProvider' }
  ];
  disputeStatusArray = [
    { name: 'Open', value: true },
    { name: 'Resolved', value: false },
  ]
  paymentFromArr = [
    { name: 'Admin', value: 'admin' },
    { name: 'IOS', value: 'ios' },
    { name: 'Android', value: 'android' },
    { name: 'Web', value: 'web' },
  ]
  identityTypeArr = [
    { name: 'Individual', value: 'individual' },
    { name: 'Startup', value: 'startup' },
    { name: 'Investor', value: 'investor' },
    { name: 'Service Provider', value: 'serviceProvider' }
  ]
  requestStatusArr = [
    { name: 'Pending', value: 'Pending' },
    { name: 'Rejected', value: 'Rejected' },
    { name: 'Accepted', value: 'Accepted' },
  ]
  public savedFilters: any = [];
  public condition: any = '$and';
  public filteredArr: any = [];
  public submitted1: boolean = false;
  public filterId: any;
  public filterCols: any = [];
  public counter: number = 0;
  BlogDetails: string;
  public setFocus: boolean = false;
  public closeFilterDropdown: boolean = false;
  public mainSearchdataSource: Observable<any>;
  public inputTextPrice = [];
  public inputTestPriceCredit = [];

  constructor(public commonService: CommonService, public showErrorService: ShowErrorService,
    private router: Router, public bsModalRef: BsModalRef, private modalService: BsModalService,
    public restrictKeyPressService: RestrictKeyPressService, public broadCaster: BroadcasterService, public zone: NgZone, public renderer: Renderer2, public localStorageService: LocalStorageService) {
    let currentPage = this.localStorageService.getToken('currentPage');
    let itemsPerPage = this.localStorageService.getToken('itemsPerPage');
    this.APIparameters = { page: 1, pagesize: itemsPerPage ? parseInt(itemsPerPage) : 10 };
    this.mainSearchdataSource = Observable.create((observer: any) => {
      observer.next(this.searchText);
    }).pipe(mergeMap((value: string) => {
      if (this.setFocus) {
        this.searchData(value);
      }
      this.setFocus = true;
      return [];
    }));
    this.modalService.onHidden.subscribe((res) => {
      if (res)
        this.callApiToGetData()
    })
    this.listingAddUpdateBroadCaster = this.broadCaster.on<boolean>('listingAddUpdate').subscribe((success) => {
      if (success) {
        this.callApiToGetData();
      }
    });
  }

  ngOnInit() {
    this.addTask();
    this.type = this.tableSetupData.type;
    this.params = this.tableSetupData.params;
    this.conditions = this.tableSetupData.conditions;
    if (this.type == 'pageslist') {
      // this.getUsers();
    }
    this.tableConfig.cols = this.tableSetupData.cols;
    this.tableConfig.actions = this.tableSetupData.actions
    this.tableConfig.cols.forEach(element => {
      if (element.drag) {
        this.colSettingCols.push(element)
      }

    });
    /*************************************************************
    @Purpose : Change statusList according to role Permission
    /*************************************************************/
    this.statusList = this.statusListData;
    const index = this.tableConfig.cols.findIndex((o) => o.type === 'multipleSelection');
    /*************************************************************/
  }
  addTask() {
    this.filteredArr.push({
    })
  }

  ngAfterViewInit() {
    this.callApiToGetData();
    // options for datatable
    this.dtOptions = {
      destroy: true,
      retrieve: true,
      searching: false,
      sorting: false,
      ordering: false,
      autoWidth: false,
      paging: false,
      info: false,
      responsive: {
        details: {
          renderer: Responsive.renderer.listHiddenNodes(),
        },
      },
      columnDefs: [
        { responsivePriority: 1, targets: 0 },
        { responsivePriority: 1, targets: -1 },
        { responsivePriority: 1, targets: -2 },
      ],
    };
  }

  ngOnDestroy() {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  /*************************************************************
    @Purpose : Api for List
  /*************************************************************/
  public getMethodName: any;
  public CSVEXCEL: any;
  public EXCEL: any;
  public DeleteUser: any;
  public ChangeStatus: any;
  public getFilterlist: any;
  public colVisible: any;
  public saveFilter: any;
  public getFilter: any;
  public deleteFilter: any;
  public deleteDownloadTemplate: any;
  public tableLength: any;
  // tslint:disable-next-line: cognitive-complexity
  async callApiToGetData(pagesize?) {
    if (pagesize) {
      this.APIparameters.pagesize = pagesize
      this.itemsPerPage = pagesize
    }
    if (this.type) {
      if (this.type === 'userlist') {
        this.getMethodName = this.callAPIConstants.UserList;
        this.CSVEXCEL = this.callAPIConstants.UserEXCELCSVforDataTable;
        this.DeleteUser = this.callAPIConstants.UserDeleteUsers;
        this.ChangeStatus = this.callAPIConstants.UserChangeStatus;
        this.downloadColumns = this.callAPIConstants.SaveDownloadTemplate;
        this.getFilterlist = this.callAPIConstants.UserGetColumnData;
        this.saveFilter = this.callAPIConstants.saveFilter;
        this.getFilter = this.callAPIConstants.UserGetFilters;
        this.deleteFilter = this.callAPIConstants.deleteFilter;
        this.colVisible = this.callAPIConstants.CommonColumnVisibleSettings;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.deleteDownloadTemplate = this.callAPIConstants.deleteDownloadTemplate;
        this.downloadUserFile = this.callAPIConstants.downloadUserFile;
      }
      else if (this.type === 'pageslist') {
        this.getMethodName = this.callAPIConstants.PagesList;
        this.ChangeStatus = this.callAPIConstants.PageChangeStatus;
        this.getFilterlist = this.callAPIConstants.UserGetColumnData;
        this.saveFilter = this.callAPIConstants.saveFilter;
        this.getFilter = this.callAPIConstants.UserGetFilters;
        this.deleteFilter = this.callAPIConstants.deleteFilter;
        this.colVisible = this.callAPIConstants.CommonColumnVisibleSettings;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
      } else if (this.type === 'adminUserlist') {
        this.downloadColumns = this.callAPIConstants.SaveDownloadTemplate;
        this.getDowloadTemplates = this.callAPIConstants.getDownloadTemplate;
        this.deleteDownloadTemplate = this.callAPIConstants.deleteDownloadTemplate;
      } else if (this.type === 'adminUserlist1') {
        this.downloadColumns = this.callAPIConstants.SaveDownloadTemplate;
        this.getDowloadTemplates = this.callAPIConstants.getDownloadTemplate;
        this.APIparameters.page = 1;
        // this.APIparameters.pagesize = 5;
      } else if (this.type === 'emailTemplateList') {
        this.getMethodName = this.callAPIConstants.EmailTemplateList;
        this.getFilterlist = this.callAPIConstants.EmailColumnData;
        this.DeleteUser = this.callAPIConstants.DeleteEmailTemplate;
        this.ChangeStatus = this.callAPIConstants.EmailChangeStatus;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
      } else if (this.type === 'cmslist') {
        this.getMethodName = this.callAPIConstants.cmsListing;
        this.DeleteUser = this.callAPIConstants.cmsDelete;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
      } else if (this.type === 'roleList') {
        this.getMethodName = this.callAPIConstants.RoleList;
        this.ChangeStatus = this.callAPIConstants.RoleChangeStatus;
        this.getFilterlist = this.callAPIConstants.RoleGetColumnData;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.DeleteUser = this.callAPIConstants.deleteRole;
      }
      else if (this.type === 'industryListing') {
        this.getMethodName = this.callAPIConstants.IndustryListing;
        this.ChangeStatus = this.callAPIConstants.changeIndustryStatus;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.DeleteUser = this.callAPIConstants.deleteIndustries;
        this.importFile = this.callAPIConstants.importIndustryFile;
        this.sampleimportFile = this.callAPIConstants.SAMPLEINDUSTRYFILE
        this.downloadColumns = this.callAPIConstants.SaveDownloadTemplate;
        this.saveFilter = this.callAPIConstants.saveFilter;
        this.getFilter = this.callAPIConstants.UserGetFilters;
        this.deleteFilter = this.callAPIConstants.deleteFilter;
        this.deleteDownloadTemplate = this.callAPIConstants.deleteDownloadTemplate;
        this.downloadUserFile = this.callAPIConstants.downloadIndustryFile;
      }
      else if (this.type === 'subIndustryListing') {
        this.getMethodName = this.callAPIConstants.SubIndustryList;
        this.ChangeStatus = this.callAPIConstants.changeSubIndustryStatus;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.DeleteUser = this.callAPIConstants.deleteSubIndustires;
        this.downloadColumns = this.callAPIConstants.SaveDownloadTemplate;
        this.saveFilter = this.callAPIConstants.saveFilter;
        this.getFilter = this.callAPIConstants.UserGetFilters;
        this.deleteFilter = this.callAPIConstants.deleteFilter;
        this.deleteDownloadTemplate = this.callAPIConstants.deleteDownloadTemplate;
        this.downloadUserFile = this.callAPIConstants.downloadSubIndustryFile;
        this.importFile = this.callAPIConstants.importSubIndustryFile;
        this.sampleimportFile = this.callAPIConstants.SAMPLESUBINDUSTRYFILE;
      }
      else if (this.type === 'skillListing') {
        this.importFile = this.callAPIConstants.importSkillFile;
        this.sampleimportFile = this.callAPIConstants.SAMPLESKILLFILE
        this.getMethodName = this.callAPIConstants.skillsListing;
        this.ChangeStatus = this.callAPIConstants.changeSkillStatus;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.DeleteUser = this.callAPIConstants.deleteSkill;
        this.downloadColumns = this.callAPIConstants.SaveDownloadTemplate;
        this.saveFilter = this.callAPIConstants.saveFilter;
        this.getFilter = this.callAPIConstants.UserGetFilters;
        this.deleteFilter = this.callAPIConstants.deleteFilter;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.deleteDownloadTemplate = this.callAPIConstants.deleteDownloadTemplate;
        this.downloadUserFile = this.callAPIConstants.downloadSkillFile;
      } else if (this.type === 'languageListing') {
        this.importFile = this.callAPIConstants.importLanguageFile;
        this.sampleimportFile = this.callAPIConstants.SAMPLELANGUAGEFILE;
        this.getMethodName = this.callAPIConstants.languageListing;
        this.ChangeStatus = this.callAPIConstants.changeLanguageStatus;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.DeleteUser = this.callAPIConstants.deleteLanguage;
        this.downloadColumns = this.callAPIConstants.SaveDownloadTemplate;
        this.saveFilter = this.callAPIConstants.saveFilter;
        this.getFilter = this.callAPIConstants.UserGetFilters;
        this.deleteFilter = this.callAPIConstants.deleteFilter;
        this.deleteDownloadTemplate = this.callAPIConstants.deleteDownloadTemplate;
        this.downloadUserFile = this.callAPIConstants.downloadUserFile;
      }
      else if (this.type == 'subAdminUserList') {
        this.getMethodName = this.callAPIConstants.SubUserList;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.ChangeStatus = this.callAPIConstants.subUserChangeStatus;
        this.DeleteUser = this.callAPIConstants.subUserDelete;
      }
      else if (this.type == 'teamMemberList') {
        this.getMethodName = this.callAPIConstants.GetTeamMembers;
        this.APIparameters['identityId'] = this.localStorageService.getToken('identityId');
      }
      else if (this.type == 'foundTeamMemberList') {
        this.getMethodName = this.callAPIConstants.GetFoundTeamMembers;
        this.APIparameters['identityId'] = this.localStorageService.getToken('identityId');
      } else if (this.type == 'investorList') {
        this.getMethodName = this.callAPIConstants.GetInvestors;
        this.APIparameters['identityId'] = this.localStorageService.getToken('identityId');
      } else if (this.type == 'feedVideosList') {
        this.getMethodName = this.callAPIConstants.VideoDetails;
        this.APIparameters['videoId'] = this.localStorageService.getToken('identityId');
      } else if (this.type == 'feedPagesList') {
        this.getMethodName = this.callAPIConstants.PageDetails;
        this.APIparameters['pageId'] = this.localStorageService.getToken('identityId');
      } else if (this.type == 'feedCommentsList') {
        this.getMethodName = this.callAPIConstants.commentDetail;
        this.APIparameters['commentId'] = this.localStorageService.getToken('identityId');
      }
      else if (this.type == 'investorRequestList') {
        this.getMethodName = this.callAPIConstants.AccreditationRequest;
        this.DeleteUser = this.callAPIConstants.DeleteAccrRequest;
      } else if (this.type == 'identityRequestList') {
        this.getMethodName = this.callAPIConstants.IdentityRequest;
        this.DeleteUser = this.callAPIConstants.DeleteIdentityRequest;
      } else if (this.type == 'reportedFeedListing') {
        this.getMethodName = this.callAPIConstants.commentListing;
        this.ChangeStatus = this.callAPIConstants.commentChangeStatus;
        this.getFilterlist = this.callAPIConstants.UserGetColumnData;
        this.saveFilter = this.callAPIConstants.saveFilter;
        this.getFilter = this.callAPIConstants.UserGetFilters;
        this.deleteFilter = this.callAPIConstants.deleteFilter;
        this.colVisible = this.callAPIConstants.CommonColumnVisibleSettings;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
      } else if (this.type == 'subscriptionListing' || this.type == 'creditListing' || this.type == 'promotionalListing') {
        this.APIparameters = '';
        this.getMethodName = this.callAPIConstants.packageListing;
        this.ChangeStatus = this.callAPIConstants.packageChangeStatus;
      } else if (this.type == 'paymentTrnListing') {
        this.getMethodName = this.callAPIConstants.paymentTrnListing;
        this.downloadUserFile = this.callAPIConstants.downloadTrnListing;
        this.downloadColumns = this.callAPIConstants.SaveDownloadTemplate;
        this.getDowloadTemplates = this.callAPIConstants.getDownloadTemplate;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.getFilterlist = this.callAPIConstants.UserGetColumnData;
        this.saveFilter = this.callAPIConstants.saveFilter;
        this.getFilter = this.callAPIConstants.UserGetFilters;
        this.deleteFilter = this.callAPIConstants.deleteFilter;
      } else if (this.type == 'creditTrnListing') {
        this.getMethodName = this.callAPIConstants.creditTrnListing;
        this.downloadUserFile = this.callAPIConstants.downloadCreditListing;
        this.downloadColumns = this.callAPIConstants.SaveDownloadTemplate;
        this.getDowloadTemplates = this.callAPIConstants.getDownloadTemplate;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.getFilterlist = this.callAPIConstants.UserGetColumnData;
        this.saveFilter = this.callAPIConstants.saveFilter;
        this.getFilter = this.callAPIConstants.UserGetFilters;
        this.deleteFilter = this.callAPIConstants.deleteFilter;
      } else if (this.type == 'disputeRequestListing') {
        this.getMethodName = this.callAPIConstants.disputeRequestListing;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.getFilterlist = this.callAPIConstants.UserGetColumnData;
        this.saveFilter = this.callAPIConstants.saveFilter;
        this.getFilter = this.callAPIConstants.UserGetFilters;
        this.deleteFilter = this.callAPIConstants.deleteFilter;
      }
    }
    if (this.getMethodName) {
      const method = (this.type == 'subscriptionListing' || this.type == 'creditListing' || this.type == 'promotionalListing') ? 'get' : 'post';
      await this.commonService.callApi(this.getMethodName, this.APIparameters, method, false, false, false).then((success) => {
        if (success.status === 1) {
          this.showSkeletonLoader1 = false;
          if (success.data.listing) {
            this.tableData.data = success.data.listing;
            this.tableLength = this.tableData.data.length
          } else if (this.type == 'subscriptionListing') {
            this.tableData.data = success.data.subscriptionPackages;
          } else if (this.type == 'creditListing') {
            this.tableData.data = success.data.addOnPackages;
          } else if (this.type == 'promotionalListing') {
            this.tableData.data = success.data.promotionalPackages;
          }
          if (this.type === 'roleList' || this.type == 'disputeRequestListing') {
            this.tableData.data.map((data) => {
              data.isTruncated = false;
              return data;
            })
          }
          this.tempSettingsData = success.data.templateSettings;
          if (success.data.templateSettings && success.data.templateSettings.length) {
            var hash = this.colors.find(a => a.name == "green").hex;
            if (document.getElementById("downloadDropdownColor"))
              document.getElementById("downloadDropdownColor").style.backgroundColor = hash;
            if (document.getElementById("filterDropdownColor"))
              document.getElementById("filterDropdownColor").style.backgroundColor = hash;
          }
          this.tableData.data.forEach((i) => {
            if (i.status) {
              if (this.type != 'identityRequestList' && this.type != 'investorRequestList' && this.type != 'subscriptionListing' && this.type != 'creditListing' && this.type != 'promotionalListing' && this.type != 'paymentTrnListing') {
                i.status = (i.status === 'Inactive') ? false : true
              }
            } else if (i.commentDetail) {
              i.commentDetail.status = (i.commentDetail.status === 'Inactive') ? false : true
            }
          })
          if (success.data.latestColumns && success.data.latestColumns.length) {
            this.colSettingCols = [];
            let arr = [];
            success.data.latestColumns.forEach((e, lindex) => {
              if (e.drag) {
                this.colSettingCols.push(e);
              }
              if (e.filter) {
                this.filterCols.push(e);
              }
              this.tableSetupData.cols.forEach((c, colIndex) => {
                if (c.colName === e.colName) {
                  arr = this.arraymove(this.tableSetupData.cols, colIndex, lindex);
                } else if (c.colName === 'Select') {
                  this.tableSetupData.cols.splice(colIndex, 1)
                }
              })
            });
            this.tableSetupData.cols = arr;
            setTimeout(() => {
              this.tableData.data = [];
              this.tableConfig.cols = [];
              this.tableSetupData.cols.unshift({ type: 'multipleSelection', colName: 'Select', colFieldname: '', isVisible: true })
              this.tableConfig.cols = this.tableSetupData.cols
              this.tableData.data = success.data.listing;
              this.tableConfig.cols.forEach(async (element) => {
                if (success.data.columnSettings && this.type !== 'adminUserlist1') {
                  success.data.columnSettings.forEach((dataApi) => {
                    if (element.colFieldname === dataApi.key) {
                      element.isVisible = dataApi.status;
                    }
                  });
                }
                else {
                  if (this.columns && this.columns.length) {
                    this.columns.forEach((dataApi) => {
                      if (element.colFieldname === dataApi.key) {
                        element.isVisible = dataApi.status;
                      }
                    });
                  }
                }
              });
              const index = this.tableConfig.cols.findIndex((o) => o.type === 'switch');
              if (this.tableConfig.cols[index]) {
                this.tableConfig.cols[index].isVisible = false;
                this.tableConfig.cols[index].filter = false;
                if (this.tableConfig.cols[index].columnVisibility) { this.tableConfig.cols[index].columnVisibility = false; }
              }
            }, 0);
          }
          else {
            this.getLatestData(success);
          }
          this.total = success.total;
          if (this.itemsPerPage > this.total) {
            this.itemsPerPage = this.total;
          }
          let that = this;
          this.itemsPerPageList = this.itemsPerPageList.filter(function (e) { return e < that.total });
          this.itemsPerPageList.push(this.total);
          this.savedFilters = success.data.filterSettings;
        } else {
          this.showErrorService.displayToastr('error', 'Something Went Wrong!');
        }
      });
    }
  }
  arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr
  }

  getLatestData(success) {
    this.tableConfig = this.tableSetupData;
    this.configs = this.tableSetupData;
    this.cols = [];
    this.filterCols = [];
    this.tableConfig.cols.forEach(element => {
      if (element.drag) {
        this.cols.push(element)
      }
      if (element.filter) {
        this.filterCols.push(element)
      }
    });
    this.tableConfig.cols.forEach(async (element) => {
      if (success.data.columnSettings && this.type !== 'adminUserlist1') {
        success.data.columnSettings.forEach((dataApi) => {
          if (element.colFieldname === dataApi.key) {
            element.isVisible = dataApi.status;
          }
        });
      }
      else {
        if (this.columns && this.columns.length) {
          this.columns.forEach((dataApi) => {
            if (element.colFieldname === dataApi.key) {
              element.isVisible = dataApi.status;
            }
          });
        }

      }
    });
    if (this.type == 'subscriptionListing') {
      this.tableData.data = success.data.subscriptionPackages;
    } else if (this.type == 'creditListing') {
      this.tableData.data = success.data.addOnPackages;
    } else if (this.type == 'promotionalListing') {
      this.tableData.data = success.data.promotionalPackages;
    } else {
      this.tableData.data = success.data.listing;
    }
    this.rerenderDataTable();

  }
  // *************************************************************//
  saveColumnSettings(tableColumnSettings) {
    this.CallAPIchangeColumnVisibility(tableColumnSettings)
  }
  /********************************************************************************
  @Purpose :  Api for downloading CSV/Excel/pdf file of datatable( with and without filter )
  /********************************************************************************/
  public count: any;
  public errorMsg = "";
  selectColumns(tableConfigCols) {
    this.count = tableConfigCols.some(ele => {
      if (ele.isSelected) {
        return true;
      } else {
        this.downloadCheckbox = false;
      }
    })
    if (!this.count) {
      delete this.options.templateName;
    }
  }
  toggleDownload() {
    this.downloadCheckbox = false;
    this.toggleDownloadButton = !this.toggleDownloadButton;
    this.downloadColumnsArr = [];
    this.tableConfig.cols.forEach(ele => {
      ele.isSelected = false;
    })
    delete this.options.templateName;
    this.count = false;
    if (this.toggleDownloadButton) {
      this.CallAPIgetDownloadTemplates();
    }
  }
  clearDownloadTemplate() {
    this.downloadCheckbox = false;
    this.downloadFilter = false;
    this.downloadColumnsArr = [];
    this.color = '#39DA8A';
    document.getElementById('downloadDropdownColor').style.backgroundColor = this.color
    this.tableConfig.cols.forEach(ele => {
      ele.isSelected = false;
    })
    delete this.options.templateName;
    this.count = false;
  }
  saveDownloadTemplate(tableConfigCols) {
    this.downloadColumnsArr = [];
    tableConfigCols.forEach((element) => {
      if (element.columnVisibility) {
        this.downloadColumnsArr.push({ key: element.colFieldname, status: element.isSelected });
      }
    });
    const data: any = { columns: this.downloadColumnsArr, color: this.color, description: this.options.templateName };
    if (this.tableSetupData.saveColumnKey) {
      data.key = this.tableSetupData.saveColumnKey;
    }
    if (this.options._id) {
      data.templateId = this.options._id;
    }
    if (this.options.templateName) {
      this.commonService.callApi(this.downloadColumns, data, 'post').then((success) => {
        if (success.status === 1) {
          this.CallAPIgetDownloadTemplates();
          this.toggleDownload();
          this.showErrorService.displayToastr('success', 'Template saved successfully');
          this.callApiToGetData();
        }
        else {
          this.showErrorService.displayToastr('error', success.message);
        }
      });
    }
    else {
      this.showErrorService.displayToastr('error', 'Please enter template Name');
    }
  }
  CallAPIdeleteDownloadTemplate(i) {
    this.showErrorService.confirmpopToast('warning', 'Are you sure,You Want To Delete ?').then((result) => {
      if (result.value === true) {
        this.commonService.callApi(this.deleteDownloadTemplate + i._id, '', 'delete', false, false).then((success) => {
          if (success.status === 1) {
            this.showErrorService.displayToastr('success', success.message);
            this.callApiToGetData();
            this.CallAPIgetDownloadTemplates();
            this.toggleDownload();
          } else { this.showErrorService.displayToastr('error', success.message); }
        });
      }

    });


  }

  showTemplate(i) {
    this.modalService.show(TemplateDetailsComponent, { initialState: { columns: i.columns, type: this.type }, ignoreBackdropClick: true, keyboard: false, class: 'modal-lg modal-dialog-centered' });
  }

  downloadTemplate(key) {
    let templateData: any = { filteredFields: [] };
    key == 'print' ? templateData.type = 'pdf' : templateData.type = key;
    this.tableConfig.cols.forEach((element) => {
      if (element.columnVisibility) {
        if (element.isSelected) {
          if (element.colDownloadName) {
            if (element.colDownloadName == 'userInfo') {
              templateData.filteredFields.push('firstName', 'lastName', 'emailId' ,'mobile');
            } else if (element.colDownloadName == 'noType') {
              templateData.filteredFields.push('totalPage','investorPageCount', 'startupPageCount', 'serviceProviderPageCount');
            } else {
              templateData.filteredFields.push(element.colDownloadName);
            }
          } else {
            if (element.colFieldname == 'createdAt') {
              templateData.filteredFields.push('createdOn');
            } else if (element.colFieldname == 'updatedAt') {
              templateData.filteredFields.push('updatedOn');
            } else {
              templateData.filteredFields.push(element.colFieldname);
            }
          }
        }
      }
    });
    this.commonService.callApi(this.downloadUserFile, templateData, 'post').then(res => {
      if (res.status == 1) {
        if (key == 'print') {
          window.open(this.downloadUrl + 'pdf/' + res.data.filePathAndName, '_blank');
        }
        else {
          this.link = this.downloadUrl + key + '/' + res.data.filePathAndName;
          fetch(this.link).then(res1 =>
            res1.blob()
          ).then((blob) => {
            var link: any = document.createElement('a');
            let objectURL = URL.createObjectURL(blob);
            link.href = objectURL;
            link.target = "_blank";
            link.download = res.data.filePathAndName;
            link.click();
            link.remove();
          });
        }
      }
      else {
        this.showErrorService.displayToastr('errror', res.message);
      }
    })
  }
  public selectDownload: any;
  public downloadFilter: boolean = false;
  editTemplate(i) {
    this.tableConfig.cols.forEach((ele) => {
      ele.isSelected = false;
    });
    let countdata = 0;
    let downloadCount = 0;
    this.selectDownload = i._id;
    this.downloadFilter = true;
    this.count = true;
    this.options.templateName = i.description;
    this.color = i.color;
    this.tableConfig.cols.forEach(ele => {
      this.downloadCheckbox = false;
      countdata++;
      i.columns.forEach(ele1 => {
        if (ele1.status && (ele1.key == ele.colFieldname)) {
          ele.isSelected = true;
          downloadCount++;
        }
      })
    })
    if ((countdata - 1) === downloadCount) {
      this.downloadCheckbox = true;
    }
    var hash = this.colors.find(a => a.name == i.color)
    if (hash)
      document.getElementById("downloadDropdownColor").style.backgroundColor = hash.hex;
  }


  methodChange(i, key) {
    i.method = key;
  }

  removeTask(index) {
    this.filteredArr.splice(index, 1);
  }

  conditionChange(key) {
    this.condition = key;
  }

  applyLatestFilter(form?, item?) {
    this.submitted = true;
    if (form.valid) {
      this.APIparameters = {
        page: 1,
        pagesize: 10,
        columnKey: this.APIparameters.columnKey,
        filter: this.filteredArr,
        condition: this.condition
      }
      this.submitted = false;
      this.callApiToGetData();
    }
  }
  /********************************************************************************
  @Purpose :  Api for downloading CSV or Excel file of datatable( with and without filter )
  /********************************************************************************/
  public download = {};
  public isFilter: boolean;

  CallAPIdownloadCSVandExcel(data, isFilter) {
    const downloadArr: any[] = [];
    this.tableConfig.cols.forEach((element) => {
      if (element.columnVisibility && element.isVisible) {
        downloadArr.push(element.colFieldname);
      }
    });
    isFilter ?
      this.download = {
        columns: downloadArr,
        filter: this.filter,
      }
      :
      this.download = {
        columns: downloadArr,
      };
    this.commonService.callApi(this.CSVEXCEL, this.download, 'post', false, false).then((success) => {
      window.open(success.data, '_blank');
    })

  }
  // *************************************************************//

  /*************************************************************
    @Purpose : select users and change status
  /*************************************************************/
  public statusFormData: any = {};
  public statusList: any[];

  selectMultipleUser(data) {
    if (data.checkbox) {
      if (this.selectedUserList.length) {
        const x = this.selectedUserList.findIndex((o) => (o === data._id) || (o === data.identityId));
        if (x === -1) {
          this.selectedUserList.push(data._id ? data._id : data.identityId);
        }
      } else {
        this.selectedUserList.push(data._id ? data._id : data.identityId);
      }
    } else {
      this.tableConfig.cols.forEach((element) => {
        element.checkbox = false;
      });
      const x = this.selectedUserList.findIndex((o) => (o === data._id) || (o === data.identityId));
      if (x > -1) {
        this.selectedUserList.splice(x, 1);
      }
    }
  }

  submitStatusForm(statusFormData?, data?) {
    if (this.selectedUserList.length) {
      // DELETE
      if (data.status === 'Delete') {
        this.showErrorService.confirmpopToast('warning', 'Do you want to delete??').then((result) => {
          if (result.value === true) {
            const data = { [this.params.deleteParams]: this.selectedUserList };
            this.CallAPIchangeStatusORDelete(this.DeleteUser, data, 'delete');
          } else {
            const index = this.tableConfig.cols.findIndex((o) => o.type === 'multipleSelection');
            this.tableConfig.cols[index].checkbox = false;
            this.deselectMultipleUser();
          }
        });
      } else if (data.status === 'Inactive' || data.status === 'Active') {
        let statusdata;
        statusdata = { [this.params.statusParams]: this.selectedUserList, status: data.status }
        this.CallAPIchangeStatusORDelete(this.ChangeStatus, statusdata, 'status');
      }
    } else {
      this.showErrorService.displayToastr('error', 'Select Users To Modify');
    }
  }
  // *************************************************************//

  /***************************************************************
    @Purpose : Pagination
  /***************************************************************/
  public itemsPerPageList = [2, 5, 10, 25, 100];

  public itemsPerPage: number = this.localStorageService.getToken('itemsPerPage') ? parseInt(this.localStorageService.getToken('itemsPerPage')) : 10;
  currentPage = this.localStorageService.getToken('currentPage') ? parseInt(this.localStorageService.getToken('currentPage')) : 1;
  pagination(e) {
    this.localStorageService.setToken('currentPage', e.page);
    this.localStorageService.setToken('itemsPerPage', e.itemsPerPage);
    this.deselectMultipleUser();
    this.APIparameters.page = e.page;
    this.itemsPerPage = e.itemsPerPage;
    this.currentPage = e.page;
    this.columnVisibilityOptions2 = false;
    this.selectedUserList = [];
    this.callApiToGetData();
  }

  selectItemPerPage(e) {
    this.customPagination._page = 1;
    this.APIparameters.pagesize = e;
    this.APIparameters.page = 1;
    this.columnVisibilityOptions2 = false;
    this.selectedUserList = [];
    this.callApiToGetData(e);
    this.deselectMultipleUser();
  }

  /***************************************************************/

  /***************************************************************
    @Purpose : change visibility of columns
  /***************************************************************/

  public columnVisibleArr: any[] = [];

  tooglecolumnVisibilityFilter() {
    this.columnVisibilityOptions = !this.columnVisibilityOptions;
    this.filterColumnOption = false;
  }


  changeCheckBoxValue() {
    let count = 0;
    this.tableConfig.cols.forEach((element) => {
      if (element.columnVisibility === true && element.isVisible === true) { count++; }
    });
    if (count === 1) {
      const index = this.tableConfig.cols.findIndex((o) => o.isVisible === true && o.columnVisibility === true);
      if (index > -1 && this.tableConfig.cols[index]) {
        this.tableConfig.cols[index].disable = true;
      }
    } else {
      this.tableConfig.cols.forEach((element) => {
        element.disable = false;
      });
    }
  }

  CallAPIchangeColumnVisibility(tableConfigCols) {
    tableConfigCols.forEach((element) => {
      if (element.columnVisibility) {
        this.columnVisibleArr.push({ key: element.colFieldname, status: element.isVisible });
      }
    });

    const data: any = { columns: this.columnVisibleArr };
    if (this.tableSetupData.saveColumnKey) {
      data.key = this.tableSetupData.saveColumnKey;
      data.latestColumns = this.colSettingCols;
    }

    this.commonService.callApi(this.colVisible, data, 'post', false, false).then((success) => {
      success.status === 1 ?
        this.showErrorService.displayToastr('success', 'Updated Successfully') : this.showErrorService.displayToastr('error', success.message);
      this.callApiToGetData();
    });
    this.columnVisibleArr = [];
  }

  selectAllColumnVisible() {
    this.tableConfig.cols.forEach((element) => {
      element.isVisible = true;
    });
  }

  resetColumnVisible() {
    this.callApiToGetData();
  }
  /***************************************************************/

  /*************************************************************
  @Purpose    : Api for change Status and Delete
  @parameters : callAPi ==> name of Api to be called
                data ==> data to be send
  /*************************************************************/
  CallAPIchangeStatusORDelete(callAPI, data, type) {
    this.commonService.callApi(callAPI, data, 'post', false, false, false).then((success) => {
      if (success.status === 1) {
        this.statusFormData = {}
        this.showErrorService.displayToastr('success', success.message);
        this.selectedUserList = [];
        this.callApiToGetData();
        const index = this.tableConfig.cols.findIndex((o) => o.type === 'multipleSelection');
        this.tableConfig.cols[index].checkbox = false;
        this.deselectMultipleUser();
      } else {
        this.showErrorService.displayToastr('error', success.message);
      }
    });
  }
  // *************************************************************//

  /*************************************************************
    @Purpose :  Rerendering Datatable
  /*************************************************************/
  async rerenderDataTable() {
    if (this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then(async (dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    } else { this.dtTrigger.next(); }
  }
  // *************************************************************//

  /*************************************************************
    @Purpose : Sorting for user listing
  /*************************************************************/

  onSorted(event) {
    this.APIparameters.sort = {};
    event.sortDirection === 'desc' ? event.sortDirection = -1 : event.sortDirection = 1;
    this.APIparameters.sort[event.sortColumn.colFieldname] = event.sortDirection;
    this.callApiToGetData();
  }
  /***************************************************************/

  /***************************************************************
    @Purpose :  Perform Action: VIEW, EDIT, DELETE And SWITCH
  /***************************************************************/

  performAction(k, i) {
    // VIEW action
    if (k.id === 1) {
      if (this.type === 'userlist') {
        this.router.navigate([this.URLConstants.USERS_DETAIL, i._id]);
      } else if (this.type === 'subAdminUserList') {
        this.router.navigate([this.URLConstants.EDIT_SUB_ADMIN, 'view', i._id]);
      } else if (this.type === 'roleList') {
        this.router.navigate([this.URLConstants.EDIT_ROLE, 'view', i._id]);
      } else if (this.type === 'cmslist') {
        this.router.navigate([this.URLConstants.EDIT_VIEW_STATIC_PAGE, 'view', i._id]);
      }
    } else if (k.id === 2) {
      if (this.type === 'userlist') {
        this.router.navigate([this.URLConstants.UPDATEUSER, i._id]);
      } else if (this.type === 'emailTemplateList') {
        this.router.navigate([this.URLConstants.EMAIL_TEMPLATE_detail, i._id]);
      } else if (this.type === 'cmslist') {
        this.router.navigate([this.URLConstants.EDIT_VIEW_STATIC_PAGE, 'edit', i._id]);
      } else if (this.type === 'roleList') {
        this.router.navigate([this.URLConstants.EDIT_ROLE, 'edit', i._id]);
      } else if ((this.type === 'industryListing') || (this.type === 'subIndustryListing') || (this.type === 'skillListing') || (this.type === 'languageListing')) {
        this.modalService.show(CommonModalComponent, { initialState: { type: this.type, id: i._id }, ignoreBackdropClick: true, keyboard: false, class: 'modal-lg modal-dialog-centered' })
      }
      else if (this.type === 'subAdminUserList') {
        this.router.navigate([this.URLConstants.EDIT_SUB_ADMIN, 'edit', i._id]);
      }
    } else if (k.id === 3) {
      this.showErrorService.confirmpopToast('warning', 'Do you want to delete??').then((result) => {
        if (result.value === true) {
          const dataObj = {
            [this.params.deleteParams]: [i._id],
          };
          this.CallAPIchangeStatusORDelete(this.DeleteUser, dataObj, 'delete');
        }
      });
    }
  }
  // SWITCH action
  changeStatus(statusData, data) {
    const dataObj = {
      [this.params.statusParams]: [data._id ? data._id : data.identityId],
      status: (statusData === true || statusData == 'Inactive') ? 'Inactive' : 'Active'
    };
    this.CallAPIchangeStatusORDelete(this.ChangeStatus, dataObj, 'status');
  }
  openModal(type) {
    if ((this.type === 'industryListing') || (this.type === 'subIndustryListing') || (this.type === 'skillListing') || (this.type === 'languageListing')) {
      this.modalService.show(CommonModalComponent, { initialState: { type: this.type }, ignoreBackdropClick: true, keyboard: false, class: 'modal-lg modal-dialog-centered' })
    }
  }
  /***************************************************************/
  movies = [
    'Episode I - The Phantom Menace',
    'Episode II - Attack of the Clones',
    'Episode III - Revenge of the Sith',
    'Episode IV - A New Hope',
    'Episode V - The Empire Strikes Back',
    'Episode VI - Return of the Jedi',
    'Episode VII - The Force Awakens',
    'Episode VIII - The Last Jedi',
    'Episode IX – The Rise of Skywalker'
  ];
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.colSettingCols, event.previousIndex, event.currentIndex);
  }
  /***************************************************************
    @Purpose :  SELECT ALL USERS IN TABLE
  /***************************************************************/
  public applyStatus: boolean = false;
  selectAllUser(i) {
    if (i.checkbox === true) {
      this.applyStatus = true;
      this.tableData.data.forEach((element) => {
        element.checkbox = true;
        if (this.selectedUserList.length) {
          if (this.type == 'reportedFeedListing') {
            const x = this.selectedUserList.findIndex((o) => (o === element.commentDetail._id));
            if (x === -1) {
              this.selectedUserList.push(element.commentDetail._id);
            }
          } else {
            const x = this.selectedUserList.findIndex((o) => ((o === element._id) || (o === element.identityId)));
            if (x === -1) {
              this.selectedUserList.push(element._id ? element._id : element.identityId);
            }
          }

        } else {
          if (this.type == 'reportedFeedListing') {
            this.selectedUserList.push(element.commentDetail._id);
          } else {
            this.selectedUserList.push(element._id ? element._id : element.identityId);
          }
        }
      });
    } else {
      this.deselectMultipleUser();
    }
  }
  /***************************************************************/

  /***************************************************************
    @Purpose :  Common Function
  /***************************************************************/
  deselectMultipleUser() {
    this.tableData.data.forEach((element) => {
      element.checkbox = false;
      this.applyStatus = false;
      if (this.type == 'reportedFeedListing') {
        const x = this.selectedUserList.findIndex((o) => o === element.commentDetail._id);
        if (x > -1) {
          this.selectedUserList.splice(x, 1);
        }
      } else {
        const x = this.selectedUserList.findIndex((o) => o === element._id);
        if (x > -1) {
          this.selectedUserList.splice(x, 1);
        }
      }
    });
    this.tableConfig.cols.forEach((element) => {
      element.checkbox = false;
    });
  }

  modifyFilterArr(fieldName, event) {
    const index = this.filter.findIndex((o) => Object.keys(o).includes(fieldName));
    if (index > -1) { this.filter[index][fieldName] = event; } else {
      this.filter.push({ [fieldName]: event });
    }
  }
  /***************************************************************/

  /***************************************************************
    @Purpose :  filter
  /***************************************************************/
  statusFilterlist: any[] = [];
  public filter = [];
  public disableApplyFilter = true;
  public disableResetFilter = true;

  toggleFilterColumn() {
    this.filterColumnOption = !this.filterColumnOption;
    this.columnVisibilityOptions = false;
  }

  getSatutsFilterlistData(colName) {
    if (colName === 'status') {
      this.statusFilterlist = ['Active', 'Inactive'];
    }
  }

  selectFilterStatus(fieldName, event) {
    const index = this.tableConfig.cols.findIndex((o) => {
      if (o.value) { return o.value.length; }
    });
    index > -1 ? this.disableApplyFilter = false : this.disableApplyFilter = true;
    if (fieldName === 'status') {
      // tslint:disable-next-line: triple-equals
      if (event == 'Active') {
        this.modifyFilterArr(fieldName, [true]);
        // tslint:disable-next-line: triple-equals
      } else if (event == 'Inactive') {
        this.modifyFilterArr(fieldName, [false]);
        // tslint:disable-next-line: triple-equals
      } else if (event == '') {
        this.modifyFilterArr(fieldName, []);
      } else {
        this.modifyFilterArr(fieldName, [true, false]);
      }
    } else {
      this.modifyFilterArr(fieldName, event);
    }
  }

  getSelectedFilterValueFromTyeAhead(data) {
    const index1 = this.tableConfig.cols.findIndex((o) => {
      if (o.value) { return o.value.length; }
    });
    index1 > -1 ? this.disableApplyFilter = false : this.disableApplyFilter = true;
    const index = this.filter.findIndex((o) => Object.keys(o).includes(data.fieldName));
    if (index > -1) { this.filter[index][data.fieldName] = data.value; } else {
      this.filter.push({ [data.fieldName]: data.value });
    }
  }

  applyFilter() {
    if (this.filter.length) {
      this.disableResetFilter = false;
      this.APIparameters.filter = this.filter;
      this.callApiToGetData();
    }
  }

  resetFilter() {
    this.disableResetFilter = true;
    this.filter = [];
    this.tableConfig.cols.forEach((element) => {
      element.value = [];
    });
    this.APIparameters.filter = [];
    this.callApiToGetData();
  }
  /***************************************************************/

  /***************************************************************
    @Purpose : SAVE and DELETE FILTER
  /***************************************************************/

  openSaveFilterModal(saveFilters: TemplateRef<any>) {
    this.bsModalRef = this.modalService.show(saveFilters, { class: 'modal-dialog-centered' });
  }



  public dataOfSaveFilter: any;
  CallAPIgetSavedFilter() {
    if (this.getFilter) {
      this.commonService.callApi(this.getFilter, '', 'get', false, false).then((success) => {
        if (success.status === 1) {
          this.dataOfSaveFilter = success.data;
        }
      });
    }
  }
  CallAPIgetDownloadTemplates() {
    if (this.getDowloadTemplates) {
      this.commonService.callApi(`${this.getDowloadTemplates}/${this.tableSetupData.saveColumnKey}`, '', 'get', false, false).then((success) => {
        if (success.status === 1) {
          this.downloadTemplatesArr = success.data;
        }
      });
    }
  }

  showSaveFilter(i) {
    this.disableResetFilter = false;
    this.APIparameters.filter = i.filter;
    this.callApiToGetData();
  }

  CallAPIdeleteSaveFilter(i) {
    this.disableResetFilter = true;
    this.showErrorService.confirmpopToast('warning', 'Do you want to delete??').then((result) => {
      if (result.value === true) {
        this.commonService.callApi(this.deleteFilter + i._id, '', 'delete', false, false).then((success) => {
          if (success.status && success.status === 1) {
            this.showErrorService.displayToastr('success', success.message);
          } else {
            this.showErrorService.displayToastr('error', success.message);
          }
          this.callApiToGetData();
        });
      }
    })
  }
  /****************************************************************************
  // To get search list
  /****************************************************************************/
  searchData(value): Observable<any> {
    if (value) {
      if (value.length >= 1) {
        this.APIparameters['searchText'] = value;
        this.APIparameters.page = 1;
        this.callApiToGetData();
        return of([]);
      } else {
        delete this.APIparameters.searchText;
        this.callApiToGetData();
        return of([]);
      }
    }
  }
  typeaheadOnBlur(event: any): void {
    this.setFocus = false;
  }
  public usersList: Array<any> = []
  getUsers(searchText?) {
    const queryParams = {
      page: 1,
      pagesize: 10,
      searchText: (searchText) ? searchText.term : ''
    };
    this.commonService.callApi(this.callAPIConstants.PAGEUSERS, queryParams, 'post', false, false).then((success) => {
      if (success.status === 1) {
        this.usersList = success.data.listing;
      }
    })
  }

  getIdentities(searchText?) {
    const queryParams = {
      page: 1,
      pagesize: 10,
      searchText: (searchText) ? searchText.term : ''
    };
    this.commonService.callApi(this.callAPIConstants.identities, queryParams, 'post', false, false).then((success) => {
      if (success.status === 1) {
        this.usersList = success.data.listing;
      }
    })
  }

  /***************************************************************/
  /***************************************************************
  @Purpose : To Get Dynamic searchValues.
  /***************************************************************/

  // For performing selected action on the list
  actionSelected(action) {
    // DELETE
    if (action === 'Delete') {
      this.showErrorService.confirmpopToast('warning', 'Do you want to delete??').then((result) => {
        if (result.value === true) {
          const data = {
            userIds: this.selectedUserList
          };
          this.CallAPIchangeStatusORDelete(this.DeleteUser, data, 'delete');
        } else {
          this.deselectMultipleUser();
        }
      });
    }
    // DELETE
    // STATUS
    if (action === 'Inactive' || action === 'Active') {
      let data;
      action === 'Inactive' ?
        data = { userIds: this.selectedUserList, status: false } :
        data = { userIds: this.selectedUserList, status: true };
      this.CallAPIchangeStatusORDelete(this.ChangeStatus, data, 'status');
      this.deselectMultipleUser();
    }
    // STATUS
  }

  // To get the selected dateType info
  dateSelected(e, index) {
    if (e.name !== 'custom') {
      var date = new Date();
      let presentDate = (e.name === 'Previous Month' || e.name === 'Previous Quarter' || e.name === 'Previous Year') ? 'month/01/year' : date.toLocaleDateString();
      let value = {
        calendarSpecificCount: e.value,
        calendarSpecificType: e.type,
        presentDate: presentDate
      }
      this.filteredArr[index]['value'] = value;
    }
  }

  // when getting startDate and endDate
  rangeSelected(e, index) {
    if (e) {
      let value = {
        startDate: moment(new Date(e[0])).format('YYYY-MM-DD'),
        endDate: moment(new Date(e[1])).format('YYYY-MM-DD')
      }
      this.filteredArr[index]['value'] = value;
    }
  }

  statusChange(data, i) {
    this.filteredArr[i]['value'] = data;
  }
  creatorChange(data, i) {
    this.filteredArr[i]['value'] = data;
  }
  valueChange(data, i) {
    this.filteredArr[i]['value'] = data;
  }
  // To clear the filter
  clearFilter(type?) {
    if (type == 'htmlclear') {
      this.filterForm.reset();
      this.descriptionForm.reset();
      this.APIparameters['filter'] = '';
      this.filteredArr = [];
      this.addTask();
    }
    this.openFilter();
    this.descriptionFilter = false;
    this.filterColor = '#4184EF'
    this.submitted = false;
    this.submitted1 = false;
    if (type != 'clear') {
      this.callApiToGetData();
    }
  }

  typeArrChanges(item, type) {
    if (type == 'contains') {
      delete (item.selectedDate);
      delete (item.value);
    } else if (type == 'page') {
      delete (item.selectedDate);
    }
  }

  // To save the filter
  toSaveFilter(form, filterName, color) {
    this.submitted1 = true;
    this.submitted = true;
    if (form && form.valid && this.filterForm && this.filterForm.valid) {
      this.APIparameters = {
        key: this.APIparameters.columnKey,
        condition: this.condition,
        description: filterName,
        color: color,
        filter: this.filteredArr,
        filterId: this.filterId
      }
      this.commonService.callApi(this.saveFilter, this.APIparameters, 'post').then((success) => {
        if (success.status && success.status === 1) {
          this.submitted1 = false;
          this.submitted = false;
          this.showErrorService.displayToastr('success', 'Filter saved successfully')
          this.APIparameters = {
            page: 1,
            pagesize: 10,
            columnKey: this.APIparameters.columnKey,
            filter: this.filteredArr,
            condition: this.condition
          }
          this.callApiToGetData();
          // this.clearFilter('clear');
        } else {
          this.showErrorService.displayToastr('error', success.message);
        }
      });
    }
  }
  filterDropdown() {
    this.closeFilterDropdown = !this.closeFilterDropdown;
  }
  public descriptionFilter: boolean = false;
  editFilter(e) {
    e.filter.forEach(element => {
      if (element.type === 'date') {
        element.dateRange[0] = new Date(element.dateRange[0]);
        element.dateRange[1] = new Date(element.dateRange[1]);
      }
    });
    this.filteredArr = e.filter;
    this.options.description = e.description;
    this.filterColor = e.color;
    this.descriptionFilter = true;
    this.condition = e.condition;
    this.filterId = e._id;
    var hash = this.colors.find(a => a.name == e.color)
    if (hash)
      document.getElementById("filterDropdownColor").style.backgroundColor = hash.hex;
  }

  removeSearch() {
    delete this.searchText;
    delete this.APIparameters.searchText;
    this.callApiToGetData();
  }

  searchRemove(e) {
    if (e.keyCode == '8') {
      this.searchData(this.searchText)
    }
  }

  selectDownloadCheckboxes(data) {
    this.tableConfig.cols.forEach(element => {
      element.isSelected = data;
      this.count = data;
    })
  }
  public fileSize: any;
  public file: any;
  public fileUpload: boolean;
  uploadFile(event) {
    if (event[0].type.includes('csv') || event[0].type.includes('vnd.ms-excel') ||
      event[0].type.includes('vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
      this.fileSize = event[0].size;
      this.fileUpload = true;
      this.file = event[0];
      let fd = new FormData();
      fd.append('file', this.file);
      this.commonService.callApi(this.importFile, fd, 'post', false, true).then((res: any) => {
        (<any>document.getElementById("import_business")).value = null;
        if (res.status == 1) {
          this.showErrorService.displayToastr('success', res.message)
          this.callApiToGetData();
        } else {
          this.showErrorService.displayToastr('error', res.message);
        }
      })
    } else {
      this.showErrorService.displayToastr('error', 'Please Upload Only .csv Extensions');
    }
  }

  sampleFile() {
    this.commonService.callApi(this.sampleimportFile, '', 'get', true, true).then((success) => {
      window.open(success.data.fileLink, '_blank');
    })
  }

  setDisableEnable(index: number, value: any) {
    if (!(this.tableData.data[index].isTruncated)) {
      this.tableData.data[index].isTruncated = true
    } else {
      this.tableData.data[index].isTruncated = false
    }
  }

  public handleAddressChange(address: Address, i) {
    i.value = {
      latitude: address.geometry.location.lat(),
      longitude: address.geometry.location.lng()
    }
  }
  gotoDetail(i) {
    this.router.navigate([this.URLConstants.PagesDetail, i.identityId], { queryParams: { identityType: i.identityType } })
  }

  reportModel(type, data, subtype) {
    this.modalService.show(ReviewCommentComponent, { initialState: { type: type, subtype: subtype, identityId: data.identityId, identityType: data.identityType }, class: 'modal-page-detail mark-dispute-resolved' })
  }

  viewRequestDetails(data, subtype) {
    this.modalService.show(AccreditationRequestComponent, { initialState: { id: data._id, subtype: subtype }, class: 'modal-page-detail mark-dispute-resolved' })
  }

  viewComments(data) {
    this.modalService.show(VideoDescriptionComponent,
      { initialState: { identityId: data.commentDetail._id, data: data.commentDetail.userInfo, videoDetail: data, type: 'feedcomment', count: data.reportsCount, like: data.commentDetail.likeTotal, comment: data.commentDetail.commentTotal }, class: 'modal-page-detail' })
  }
  gotoURL(url, type?) {
    if (type == 'download') {
      // this.link = this.pdfUrl + url;
      // fetch(this.link).then(res1 =>
      //   res1.blob()
      // ).then((blob: any) => {
      //   var link: any = document.createElement('a');
      //   let objectURL = URL.createObjectURL(blob);
      //   link.href = objectURL;
      //   link.target = "_blank";
      //   link.download = url;
      //   link.click();
      //   link.remove();
      // });
      window.open(this.pdfUrl + url, '_blank');
    } else
      window.open('//' + url, '_blank');
  }
  public price;
  public pricepromotional;
  changeToInputText(id, type, cancel) {
    if (type == 'price') {
      this.inputTextPrice[id] = !this.inputTextPrice[id];
      this.price = cancel;
    }
    else {
      this.inputTestPriceCredit[id] = !this.inputTestPriceCredit[id];
      this.pricepromotional = cancel;
    }
  }
  public typePrice: any;
  updateInputText(data, i, id, type, subtype) {
    if (this.type == 'creditListing') {
      this.typePrice = 'addOn';
    } else if (this.type == 'promotionalListing') {
      this.typePrice = 'promotional';
    }
    let dataPrice = {
      subscriptionId: i._id,
      type: this.typePrice,
      [(type == 'priceCredits') ? subtype : type]: Number(data)
    }
    this.commonService.callApi(this.callAPIConstants.packageUpdate, dataPrice, 'post', false, false).then((success) => {
      if (success.status == 1) {
        this.showErrorService.displayToastr('success', success.message);
        this.callApiToGetData();
        setTimeout(() => {
          if (subtype == 'price')
            this.inputTextPrice[id] = !this.inputTextPrice[id];
          else
            this.inputTestPriceCredit[id] = !this.inputTestPriceCredit[id];
        }, 1000);
      }
    })
  }

  gotoUserData(i) {
    this.router.navigate([this.URLConstants.USERS_DETAIL, i.userId])
  }

  openVideo(data) {
    this.modalService.show(ViewVideosComponent,
      { initialState: { data: data, type: 'video' }, class: 'introduction-wrap modal-dialog-centered' });
  }

  goToDetails(id, type, i) {
    if (type == 'individual') {
      this.router.navigate([this.URLConstants.USERS_DETAIL, id])
    } else {
      this.gotoDetail(i)
    }
  }

  gotoDisputeDetail(id) {
    this.router.navigate([this.URLConstants.DisputeDetail, id])
  }
}
