import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/common/service/common.service';
import { environment } from 'src/environments/environment';
import { callAPIConstants } from '../../../../common/constants/callAPI-constants';
import { URLConstants } from '../../../../common/constants/routerLink-constants';

@Component({
  selector: 'app-accreditation-request',
  templateUrl: './accreditation-request.component.html',
  styleUrls: []
})
export class AccreditationRequestComponent implements OnInit {
  id;
  subtype;
  callAPIConstants = callAPIConstants;
  imgUrl = environment.uploadImgUrl;
  public URLConstants = URLConstants;
  public pdfUrl = environment.pdfUrl;
  public reportData: any = {};
  public link;

  constructor(public modalRef: BsModalRef, public commonservice: CommonService) { }

  ngOnInit(): void {
    const method = (this.subtype == 'accreditation') ? this.callAPIConstants.AccreditationRequestDetail : this.callAPIConstants.IdentityRequestDetail
    this.commonservice.callApi(method + this.id, '', 'get', false, false).then((success) => {
      if (success.status == 1) {
        this.reportData = success.data;
      }
    })
  }
  gotoURL(url) {
    // this.link = this.pdfUrl + url;
    // fetch(this.link).then(res1 => res1.blob()) // Gets the response and returns it as a blob
    //   .then((blob: any) => {
    //     var link: any = document.createElement('a');
    //     let objectURL = URL.createObjectURL(blob);
    //     link.href = objectURL;
    //     link.target = "_blank";
    //     link.download = url;
    //     link.click();
    //     link.remove();
    //   });
    window.open(this.pdfUrl + url, '_blank');
  }
}
