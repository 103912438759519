<style>
    .color-squre {
        height: 40px;
        width: 40px;
        border-radius: 4px;
        display: inline-block;
        margin-left: 8px;
        border: 0;
        cursor: pointer;
    }

    .color-squre-mini {
        height: 10px;
        width: 10px;
        border-radius: 4px;
        display: inline-block;
        margin-left: 8px;
        border: 0;
        cursor: pointer;
    }

    .ng-select.ng-select-single .ng-select-container {
        height: 38px !important;
    }

    .filter-dropdownmenu {
        top: 6.90rem !important;
    }

    .color-primary {
        position: relative;
    }

    .color-primary .color-squre {
        background: #5A4FC4;
    }

    .color-secound .color-squre {
        background: #F19A64;
    }

    .example-list {
        width: 500px;
        max-width: 100%;
        border: solid 1px #ccc;
        min-height: 60px;
        display: block;
        background: white;
        border-radius: 4px;
        overflow: hidden;
    }

    .example-box {
        padding: 20px 10px;
        border-bottom: solid 1px #ccc;
        color: rgba(0, 0, 0, 0.87);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        cursor: move;
        background: white;
        font-size: 14px;
    }

    .cdk-drag-preview {
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    }

    .cdk-drag-placeholder {
        opacity: 0;
    }

    .cdk-drag-animating {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }

    .example-box:last-child {
        border: none;
    }

    .example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }

    .download-dropdown .download-dropdown-menu {
        width: 100%;
        color: var(--dt-text-color);
        top: 3rem !important;
        position: absolute;
        right: 0;
        z-index: 1000;
        float: left;
        min-width: 800px;
        margin: .125rem 0 0;
        font-size: 1rem;
        text-align: left;
        list-style: none;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: .25rem;
        animation: 0.8s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
        border-radius: 5px;
        box-shadow: -7px 8px 16px 0px rgba(55, 70, 95, 0.2);
        background: var(--input-bg);
        display: none;
    }

    .left-col {
        flex: 0 0 20%;
        min-width: 175px;
        height: 350px;
        overflow-y: auto;
    }

    .left-col .list-group-item {
        border: none;
        border-radius: 0;
        align-items: center;
        padding: 15px 10px 15px 20px;
        background: var(--input-bg);
    }

    .left-col .list-group-item .handle {
        margin-right: 10px;
    }

    .right-col {
        width: calc(100% -175px);
    }

    .addIcon {
        outline: none;
    }

    .left-col .list-group-item .handle .custom-checkbox label {
        margin: 0;
        font-size: 0.875rem !important;
        line-height: 20px !important;
        padding-left: 38px;
        color: var(--input-color) !important;
        font-weight: 600 !important;
    }


    .bs-datepicker {
        transform: translate3d(306px, 338px, 0px) !important;
    }

    .right-col .col-left {
        flex: 0 0 65%;
        margin-right: 1rem;
    }

    .right-col .col-right {
        flex: 0 0 auto;
    }

    .right-col .col-right .right-col-list {
        max-height: 200px;
        overflow: auto;
    }

    .template-list-outer {
        height: 220px;
        overflow-y: auto;
    }

    .template-list-outer .template-list .list-group-item {
        padding-top: 9px;
        padding-bottom: 9px;
        border: 0;
    }

    .download-dropdown.open .download-dropdown-menu {
        display: block;
    }

    .bottom-buttons button {
        min-width: 90px;
        color: var(--text-color);
        border-color: var(--border-color);
        background: var(--bg-white);
    }

    .list-group-item {
        border: none;
        border-radius: 0;
        align-items: center;
        margin-bottom: 10px;
        background: var(--input-bg)
    }

    .list-group-item label {
        margin: 0;
        font-size: 0.875rem !important;
        line-height: 20px !important;
        padding-left: 38px;
        color: var(--input-color) !important;
    }

    .filter-form {
        position: relative;
    }

    .field-form {
        width: calc(100% - 80px);
    }

    .filter-form .row-add {
        /* position: absolute;
        right: 10px;
        bottom: 16px; */
        margin: 6px 2px 6px 10px;

    }

    .filter-form .row-add em {
        font-size: 25px;
    }

    .save-filter {
        display: flex;
        margin: 0 10px 10px 0;
        min-width: 135px;
        flex-wrap: wrap;
    }

    .caption-image {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;
        display: inline-block;
    }

    .caption-image img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }

    table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before,
    table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child:before {
        top: 21px;
    }

    table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child,
    table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child {
        position: absolute;
        padding-left: 30px;
        cursor: pointer;
    }

    table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before {
        top: 9px;
    }

    table.dataTable tbody tr {
        background: var(--bg-white);
    }

    table.dataTable.no-footer {
        border-bottom: none !important;
    }

    .error-block {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #FF5B5C;
    }

    .group-input .input-group button {
        margin: 0 !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }




    @media screen and (max-width: 991px) {
        .btn .minW-md-0 {
            min-width: unset;
        }
    }

    table.dataTable thead th {
        padding: 1rem !important;
    }

    table.dataTable thead th:first-child {
        padding-left: 30px !important;
    }

    table.dataTable thead th:nth-child(2) {
        min-width: 100px;
    }

    table.dataTable tbody td {
        padding: .5rem 1rem !important;
    }

    table.dataTable tbody td:first-child {
        padding-left: 30px !important;
    }

    .show-content {
        display: block !important;
    }

    .underline-on-hover:hover {
        text-decoration: underline;
    }

    .video-box {
        width: 150px;
        height: 100px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: 5px;

    }

    .video-box img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    .video-box .position-ab {
        position: absolute;
        width: 30px;
        height: 30px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 1);
    }

    .page-no-type {
        cursor: pointer;
    }
</style>
<!-- BEGIN : Show Skeleton Loader -->
<div class="content-wrapper" [hidden]="!showSkeletonLoader1">
    <div class="card animated fadeIn">
        <div class="card-header">
            <div class="row">
                <div class="col-md-6"></div>
                <div class="col-md-6">
                    <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
        <div class="card-body">
            <ngx-skeleton-loader count="5"></ngx-skeleton-loader>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-md-6"></div>
                <div class="col-md-6">
                    <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- END : Show Skeleton Loader -->

<div class="content-wrapper" [hidden]="showSkeletonLoader1">
    <div class="animated fadeIn">
        <!-- TABLE HEADER START-->
        <!-- ON COLLAPSE COLUMN HIDE SHOW -->
        <div class="card-body p-0">
            <!-- Data Table Start -->
            <!-- filter/download/search buttons -->
            <div class="user-listing-filterOptions mb-2 d-block">
                <div class="row mb-2">
                    <div class="col-xl-5 position-static d-flex flex-wrap">
                        <div class="left-buttons d-flex ">
                            <label *ngIf="this.conditions.search">
                                <div class="search-input-wrapper position-relative">
                                    <form>
                                        <div class="form-group position-relative">
                                            <em class="bx bx-search"></em>
                                            <input type="text" class="form-control icon-search"
                                                typeaheadOptionField="searchText" [typeaheadMinLength]="0"
                                                (focus)="typeaheadOnBlur($event)" autocomplete="off"
                                                (blur)="typeaheadOnBlur($event)" placeholder="Search"
                                                typeaheadWaitMs="700" [(ngModel)]="searchText"
                                                [typeahead]="mainSearchdataSource"
                                                (Keyup.backspace)="searchRemove($event)">
                                            <div class="remove-icon"><em class="bx bx-x" *ngIf="searchText"
                                                    (click)="removeSearch()"></em></div>
                                        </div>
                                    </form>
                                </div>
                            </label>
                        </div>
                        <button (click)="openFilter()" *ngIf="conditions.showFilter"
                            class="btn btn-default dropdown-toggle minW-md-0 btn-bg-white f-btn" type="button">
                            <em class="bx bx-filter d-lg-none"></em>
                            <span class="d-none d-sm-none d-lg-inline-block">Filter Data</span>
                        </button>
                    </div>
                    <div class="col-xl-7">
                        <div class="right-buttons d-flex justify-content-end align-items-start">
                            <div *ngIf="conditions.showFilterImport" class="d-flex flex-wrap">
                                <span class="text-label d-inline-block mt-2 up-data">Upload Data</span>
                                <div class="d-flex flex-column">
                                    <label class="btn btn-default  minW-md-0 btn-bg-white mb-0 ml-2" type="button">
                                        <em class="bx bx-columns d-lg-none"></em>
                                        <input type="file" size="2" id="import_business"
                                            (change)="uploadFile($event.target.files)" accept=".csv" class="d-none">
                                        CSV
                                    </label>
                                    <a href="javascript:;" (click)="sampleFile()" class="d-block mt-1 hover-text"
                                        style="text-decoration: underline;">Download Sample
                                        File</a>
                                </div>
                            </div>
                            <div *ngIf="this.conditions.showExport" dropdown
                                class="download-btn download-dropdown d-flex">
                                <div *ngIf="this.conditions.showApplyStatus" class="d-flex">
                                    <form #statusForm="ngForm">
                                        <ng-select class="ng-select-line" [items]="statusList" bindLabel="name"
                                            bindValue="value" placeholder="Select Status" [clearable]='false'
                                            [searchable]='false' [dropdownPosition]="'auto'" #status="ngModel"
                                            name="status" [(ngModel)]="statusFormData.status" required>
                                        </ng-select>
                                    </form>
                                    <button type="button" class="btn btn-primary ml-0 apply_btn"
                                        (click)="submitStatusForm(statusForm,statusFormData)">Apply</button>
                                </div>
                                <button id="button-autoclose3" *ngIf="conditions.showDownload" dropdownToggle
                                    class="btn btn-default dropdown-toggle minW-md-0 btn-bg-white ml-2" type="button"
                                    aria-controls="dropdown-autoclose3" aria-haspopup="true" aria-expanded="false">
                                    <em class="bx bx-download d-lg-none"></em>
                                    <span class="d-none d-sm-none d-lg-inline-block">Download</span>
                                </button>
                                <div id="dropdown-autoclose3" (click)="$event.stopPropagation()" *dropdownMenu
                                    class="download-dropdown-menu pt-3 pr-3 pb-3 pl-0 dropdown-menu-right dropdown-icon"
                                    aria-labelledby="button-autoclose3">
                                    <div class="container-fluid">
                                        <div class="row flex-nowrap">
                                            <div class="left-col p-0">
                                                <ul class="list-group handle-list border-left-0 mr-2">
                                                    <li class="list-group-item">
                                                        <div class="custom-checkbox">
                                                            <label>
                                                                <input type="checkbox" name="downloadCheckbox"
                                                                    [(ngModel)]="downloadCheckbox"
                                                                    (change)="selectDownloadCheckboxes(downloadCheckbox)">
                                                                <span></span>
                                                                Select All
                                                            </label>
                                                        </div>
                                                    </li>
                                                    <ng-container *ngFor="let i of tableConfig?.cols"
                                                        [ngClass]="(i.columnVisibility)?'':''">
                                                        <li *ngIf="i.columnVisibility" class="list-group-item">
                                                            <div class="custom-checkbox">
                                                                <label>
                                                                    <input type="checkbox" name="status"
                                                                        (change)="selectColumns(tableConfig?.cols)"
                                                                        [(ngModel)]="i.isSelected">
                                                                    <span></span>
                                                                    {{i.colDownload}}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    </ng-container>
                                                </ul>
                                            </div>
                                            <div
                                                class="right-col p-0 border-top-0 border-bottom-0 border-right-0 border pl-3">
                                                <div class="d-flex m-0">
                                                    <div class="col-left">
                                                        <form #downloadForm="ngForm"
                                                            (ngSubmit)="saveDownloadTemplate(tableConfig?.cols)">
                                                            <div class="input-group mb-3">
                                                                <input type="text" class="form-control"
                                                                    placeholder="Template Name"
                                                                    [(ngModel)]="options.templateName"
                                                                    #templateName="ngModel" [disabled]="!count">
                                                                <div class="input-group-append">
                                                                    <button class="btn btn-primary" type="submit"
                                                                        [disabled]="!count">Save
                                                                        Template</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div class="col-right">
                                                        <div class="dropdown colorbox m-0">
                                                            <button (click)="openCloseColorDropdown()"
                                                                class="btn dropdown-toggle dropdown-btn" type="button"
                                                                data-toggle="dropdown" aria-haspopup="true"
                                                                aria-expanded="false">
                                                                <span id="downloadDropdownColor"
                                                                    class="color-box"></span>
                                                            </button>
                                                            <ul class="dropdown-menu dropdown-menu-right dropdown-icon"
                                                                [ngClass]="statusColorPicker ? 'show' : ''"
                                                                aria-labelledby="dropdownMenuButton2">
                                                                <li (click)="selectColor('#FDAC41','yellow')"
                                                                    [ngClass]="('yellow' == this.color) ? 'active' :''">
                                                                    <a class="dropdown-item"><span
                                                                            class="color-shade bg-yellow"></span><span
                                                                            class="color-text">Yellow</span></a>
                                                                </li>
                                                                <li (click)="selectColor('#39DA8A','green')"
                                                                    [ngClass]="('green' == this.color) ? 'active' :''">
                                                                    <a class="dropdown-item"><span
                                                                            class="color-shade bg-green"></span><span
                                                                            class="color-text">Green</span></a>
                                                                </li>
                                                                <li (click)="selectColor('#b87ee5','purple')"
                                                                    [ngClass]="('purple' == this.color) ? 'active' :''">
                                                                    <a class="dropdown-item"><span
                                                                            class="color-shade bg-purple"></span><span
                                                                            class="color-text">Purple</span></a>
                                                                </li>
                                                                <li (click)="selectColor('#FF5B5C','red')"
                                                                    [ngClass]="('red' == this.color) ? 'active' :''">
                                                                    <a class="dropdown-item"><span
                                                                            class="color-shade bg-red"></span><span
                                                                            class="color-text">Red</span></a>
                                                                </li>
                                                                <li (click)="selectColor('#b3c0ce','gray')"
                                                                    [ngClass]="('gray' == this.color) ? 'active' :''">
                                                                    <a class="dropdown-item"><span
                                                                            class="color-shade bg-gray"></span><span
                                                                            class="color-text">Gray</span></a>
                                                                </li>
                                                                <li (click)="selectColor('#00cfdd','blue')"
                                                                    [ngClass]="('blue' == this.color) ? 'active' :''">
                                                                    <a class="dropdown-item"><span
                                                                            class="color-shade bg-skyblue"></span><span
                                                                            class="color-text">Sky Blue</span></a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-right">
                                                        <button (click)="clearDownloadTemplate()"
                                                            class="btn btn-primary" type="button"
                                                            style="margin-left: 10px;"
                                                            [disabled]="!count">Clear</button>
                                                    </div>
                                                </div>
                                                <div class="row m-0 flex-column">
                                                    <div class="scrollable template-list-outer right-col-list">
                                                        <ul class="list-group template-list select-template-list">
                                                            <li class="list-group-item d-flex align-items-center flex-wrap"
                                                                [ngClass]="((selectDownload == temp._id) && downloadFilter) ? 'selected' : ''"
                                                                *ngFor="let temp of tempSettingsData" [id]="temp._id">
                                                                <div class="d-flex align-items-start filter-label">
                                                                    <span style="cursor: default"
                                                                        [style.background]="temp.color"
                                                                        id="filterSquareMini"
                                                                        class="color-squre-mini mr-1" [disabled]="true"
                                                                        [cpOKButtonClass]="'btn btn-primary btn-md'"></span>
                                                                    <span style="cursor: pointer" class="item-name"
                                                                        (click)="editTemplate(temp)">{{temp.description}}
                                                                    </span>
                                                                </div>

                                                                <span
                                                                    class="bx bx-trash-alt mr-1 cursor-pointer text-danger ml-2"
                                                                    (click)="CallAPIdeleteDownloadTemplate(temp)">
                                                                </span>
                                                                <span aria-hidden="true" tooltip="Edit"
                                                                    placement="bottom"
                                                                    class="bx bx-edit text-primary cursor-pointer ml-2"
                                                                    (click)="editTemplate(temp)"></span>
                                                                <div *ngIf="!tempSettingsData?.length">
                                                                    No Templates Found
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div
                                                        class="bottom-buttons border-left-0 border-bottom-0 border-right-0 border mt-2 mb-2 pt-3">
                                                        <button (click)="downloadTemplate('excel')" type="button"
                                                            class="btn border mr-2" id="ExportReporttoExcel"
                                                            [disabled]="!count">
                                                            <span class="mr-2">
                                                                <img class="img-fluid"
                                                                    src="assets/images/icon/excel-icon.svg"
                                                                    alt="Excel" />
                                                            </span>
                                                            Excel
                                                        </button>
                                                        <button (click)="downloadTemplate('pdf')" type="button"
                                                            class="btn border mr-2" id="ExportReporttoExcel"
                                                            [disabled]="!count">
                                                            <span class="mr-2">
                                                                <img class="img-fluid"
                                                                    src="assets/images/icon/excel-icon.svg"
                                                                    alt="Excel" />
                                                            </span>
                                                            Pdf
                                                        </button>
                                                        <button (click)="downloadTemplate('csv')" type="button"
                                                            class="btn border" id="ExportReporttoCSV"
                                                            [disabled]="!count">
                                                            <span class="mr-2">
                                                                <img class="img-fluid"
                                                                    src="assets/images/icon/csv-icon.svg" alt="Excel" />
                                                            </span>
                                                            CSV
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div *ngIf="this.conditions.showImport" class="dropdown importFileDropdown custom-dropdown"
                                dropdown>
                                <button class="btn glow-secondary btn-secondary dropdown-toggle pr-4 minW-md-0"
                                    type="button" aria-controls="dropdown-animated">
                                    <em class="bx bxs-file-import d-lg-none"></em>
                                    <span class="d-none d-sm-none d-lg-inline-block">Import File</span>
                                </button>
                                <ul class="dropdown-menu p-0 dropdown-menu-right dropdown-icon" *dropdownMenu
                                    class="dropdown-menu" role="menu" aria-labelledby="dropdown-animated">
                                    <li><a class="dropdown-item" href="#"><em
                                                class='bx bx-trash-alt  mr-2 icon-btn'></em>CSV
                                            File</a></li>
                                    <li><a class="dropdown-item" href="#"><em
                                                class='bx bx-notepad  mr-2 icon-btn'></em>Excel
                                            File</a></li>
                                </ul>
                            </div>
                            <ng-container *ngIf="accessPermission.create && accessPermission.read">
                                <ng-container *ngIf="this.conditions.showButton && !conditions.modal ">
                                    <button type="button" class="nav-link  btn btn-primary listlink"
                                        [hidden]="conditions.showButton.hidden" style="margin-left: 10px;"
                                        [routerLink]="[conditions.showButton.routerLink]">
                                        <em class="fa fa-plus pr-2" aria-hidden="true"></em>
                                        <span>{{this.conditions.showButton.buttonName}}</span>
                                    </button>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="accessPermission.create && accessPermission.read">
                                <ng-container *ngIf="this.conditions.showButton  && conditions.modal">
                                    <button style="margin-left: 10px;" type="button" class="btn btn-primary listlink"
                                        (click)="openModal(type)">
                                        <em class="fa fa-plus pr-2" aria-hidden="true"></em>
                                        <span>{{this.conditions.showButton.buttonName}}</span>
                                    </button>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                    <div *ngIf="this.conditions.showFilter"
                        class="custom-dropdown filter-data-dropdown w-100 position-static ml-2"
                        [ngClass]="status ? 'open' : ''">
                        <div class="custom-dropdown-menu w-100 dropdown-icon" (click)="$event.stopPropagation()"
                            #filter>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-md-12 mb-2">
                                        <!-- Get Filters -->
                                        <div class="d-flex flex-wrap align-items-center status-list">
                                            <div *ngFor="let i of savedFilters" class="save-filter rounded mt-2"
                                                [ngClass]="((i._id == filterId) && descriptionFilter) ? 'selected' :'' ">
                                                <span style="cursor: default" [style.background]="i.color"
                                                    class="color-squre-mini" [(colorPicker)]="i.color" [disabled]="true"
                                                    [cpOKButtonClass]="'btn btn-primary btn-md'"></span>
                                                <span class="ml-2" style="cursor: pointer"
                                                    (click)="editFilter(i)">{{i.description}}
                                                </span>
                                                <span class="bx bx-trash-alt mr-1 cursor-pointer text-danger ml-2"
                                                    aria-hidden="true" tooltip="Delete" placement="bottom"
                                                    (click)="CallAPIdeleteSaveFilter(i)"></span>
                                                <span class="bx bx-edit text-primary cursor-pointer ml-2"
                                                    aria-hidden="true" tooltip="Edit" placement="bottom"
                                                    (click)="editFilter(i)"></span>
                                            </div>
                                        </div>
                                        <div *ngIf="!savedFilters?.length">
                                            No save filters Found
                                        </div>
                                        <!-- Get Filters -->
                                    </div>
                                    <div class="col-md-12">
                                        <div class="row">
                                            <!-- New code  -->
                                            <form #filterForm="ngForm" class="filter-form w-100">
                                                <div class="col-md-12">
                                                    <div class="filter-details-form"
                                                        *ngFor="let i of filteredArr; let index=index">
                                                        <div class="field-form">
                                                            <div class="target-form">
                                                                <div class="target-details">
                                                                    <div class="row align-items-start">
                                                                        <div class="col-md-6 col-lg-4 mb-2">
                                                                            <ng-select [items]="filterCols"
                                                                                [(ngModel)]="i.key"
                                                                                bindLabel="filterCol"
                                                                                bindValue="colFieldname"
                                                                                [ngClass]="((submitted || colFieldname.touched) && colFieldname.invalid)?'has-danger':''"
                                                                                name="colFieldname{{index}}"
                                                                                placeholder="Select Field"
                                                                                #colFieldname="ngModel"
                                                                                [multiple]="false" required
                                                                                [hideSelected]="true">
                                                                            </ng-select>
                                                                            <div class="error-block text-left"
                                                                                *ngIf="((submitted || colFieldname.touched) && colFieldname.invalid)">
                                                                                Select Field
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 col-lg-4 mb-2">
                                                                            <ng-select class="ng-select-line"
                                                                                [items]="typeArr" bindValue="value"
                                                                                bindLabel="name" placeholder="Select"
                                                                                [clearable]='true' [multiple]="false"
                                                                                [dropdownPosition]="'auto'"
                                                                                [closeOnSelect]="true"
                                                                                name="contains{{index}}"
                                                                                #contains="ngModel" required
                                                                                [(ngModel)]="i.type"
                                                                                (change)="typeArrChanges(i,'contains')">
                                                                            </ng-select>
                                                                            <div class="error-block text-left"
                                                                                *ngIf="((submitted || contains.touched) && contains.invalid)">
                                                                                Select Type
                                                                            </div>
                                                                        </div>
                                                                        <!-- Contains -->
                                                                        <div class="col-md-6 col-lg-4 mb-2"
                                                                            *ngIf="((i.type !== 'date') && (i.type !== 'date_time') && (i.key !=='createByName') && (i.key !=='status') && (i.key != 'locationPoint') &&( i.key != 'type') && (i.key != 'subscriptionIds')&& (i.key != 'role') && (i.key!= 'paymentFrom')&& (i.key!= 'totalPage') && (i.key!= 'reqStatus') && (i.key != 'userIds') && (i.key != 'raisedBy') && (i.key != 'raisedAgainst') && (i.key != 'createdBy') && (i.key != 'reportedBy') && (i.key != 'isPending') && (i.key != 'identityType'))">
                                                                            <input type="text" class="form-control"
                                                                                placeholder="" name="value{{index}}"
                                                                                [(ngModel)]="i.value" autocomplete="off"
                                                                                (keydown)=" (i.type == 'greaterThan' || i.type=='lessThan' ? restrictKeyPressService.AllowNumbers($event) : '')"
                                                                                #value="ngModel" required>
                                                                            <div class="error-block text-left"
                                                                                *ngIf="((submitted || value.touched) && value.invalid)">
                                                                                Enter Value
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 col-lg-4 mb-2"
                                                                            *ngIf="(((i.key == 'userIds') || (i.key == 'createdBy') || (i.key == 'reportedBy')) && (i.type == 'contains'))">
                                                                            <ng-select class="ng-select-line"
                                                                                [items]="usersList" bindValue="_id"
                                                                                bindLabel="name"
                                                                                placeholder="Select who created post"
                                                                                [clearable]='true' [multiple]="true"
                                                                                [dropdownPosition]="'auto'"
                                                                                [searchable]="true"
                                                                                [closeOnSelect]="true"
                                                                                name="value{{index}}" #value="ngModel"
                                                                                required [(ngModel)]="i.value"
                                                                                (search)="getUsers($event)"
                                                                                (change)="valueChange(i.value,index)">
                                                                            </ng-select>
                                                                            <div class="error-block text-left"
                                                                                *ngIf="((submitted || value.touched) && value.invalid)">
                                                                                Select who created post
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 col-lg-4 mb-2"
                                                                            *ngIf="(((i.key == 'raisedBy') || (i.key == 'raisedAgainst')) && (i.type == 'contains'))">
                                                                            <ng-select class="ng-select-line"
                                                                                [items]="usersList" bindValue="_id"
                                                                                bindLabel="name" placeholder="Select"
                                                                                [clearable]='true' [multiple]="true"
                                                                                [dropdownPosition]="'auto'"
                                                                                [searchable]="true"
                                                                                [closeOnSelect]="true"
                                                                                name="value{{index}}" #value="ngModel"
                                                                                required [(ngModel)]="i.value"
                                                                                (search)="getIdentities($event)"
                                                                                (change)="valueChange(i.value,index)">
                                                                            </ng-select>
                                                                            <div class="error-block text-left"
                                                                                *ngIf="((submitted || value.touched) && value.invalid)">
                                                                                Select
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 col-lg-4 mb-2"
                                                                            *ngIf="(i.key === 'createByName') && (i.type == 'contains')">
                                                                            <ng-select class="ng-select-line"
                                                                                [items]="creatorListData"
                                                                                bindValue="value" bindLabel="name"
                                                                                placeholder="Select" [clearable]='true'
                                                                                [multiple]="false"
                                                                                [dropdownPosition]="'auto'"
                                                                                [closeOnSelect]="true"
                                                                                name="creator{{index}}"
                                                                                #creator="ngModel" required
                                                                                [(ngModel)]="i.creator"
                                                                                (change)="creatorChange(i.creator,index)">
                                                                            </ng-select>
                                                                            <div class="error-block text-left"
                                                                                *ngIf="((submitted || creator.touched) && creator.invalid)">
                                                                                Select Creator
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 col-lg-4 mb-2"
                                                                            *ngIf="(i.key === 'status') && (i.type == 'contains')">
                                                                            <ng-select [items]="statusListData"
                                                                                placeholder="Select" bindLabel="name"
                                                                                bindValue="value" [clearable]='false'
                                                                                [searchable]='false'
                                                                                [dropdownPosition]="'auto'"
                                                                                #status="ngModel" name="status"
                                                                                [(ngModel)]="i.status"
                                                                                (change)="statusChange(i.status,index)"
                                                                                required>
                                                                            </ng-select>
                                                                            <div class="error-block text-left"
                                                                                *ngIf="((submitted || status.touched) && status.invalid)">
                                                                                Select Status
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 col-xl-4 col-lg-3 mb-2"
                                                                            *ngIf="((i.type === 'date' || i.type == 'date_time')) && (i.key == 'createdAt' || i.key == 'lastReportedDate' || i.key == 'updatedAt')">
                                                                            <input placeholder="Date range picker"
                                                                                type="text" class="form-control"
                                                                                bsDaterangepicker
                                                                                [(ngModel)]="i.dateRange"
                                                                                [outsideClick]="true"
                                                                                name="daterange{{index}}"
                                                                                #daterange="ngModel"
                                                                                (bsValueChange)="rangeSelected($event, index)"
                                                                                [maxDate]="today" required>
                                                                            <div class="error-block text-left"
                                                                                *ngIf="((submitted || daterange?.touched) && daterange?.invalid)">
                                                                                Select Date Range
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 col-xl-4 col-lg-3 mb-2 "
                                                                            *ngIf="(i.type === 'equal') && (i.key == 'locationPoint')">
                                                                            <input ngx-google-places-autocomplete
                                                                                #placesRef="ngx-places"
                                                                                placeholder="Location"
                                                                                name="locationPoint" id=""
                                                                                class="form-control"
                                                                                [(ngModel)]="i.locationPoint"
                                                                                #locationPoint="ngModel"
                                                                                (onAddressChange)="handleAddressChange($event,i)"
                                                                                required />
                                                                            <div class="error-block text-left"
                                                                                *ngIf="((submitted || locationPoint?.touched) && locationPoint?.invalid)">
                                                                                Enter location
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 col-xl-4 col-lg-3 mb-2 "
                                                                            *ngIf="(i.type === 'equal') && (i.key == 'type')">
                                                                            <ng-select class="ng-select-line"
                                                                                [items]="pageTypeArr" bindValue="value"
                                                                                bindLabel="name" placeholder="Select"
                                                                                [clearable]='true' [multiple]="true"
                                                                                [dropdownPosition]="'auto'"
                                                                                [closeOnSelect]="true"
                                                                                name="type{{index}}" #type="ngModel"
                                                                                required [(ngModel)]="i.value"
                                                                                (change)="typeArrChanges(i,'page')">
                                                                            </ng-select>
                                                                            <div class="error-block text-left"
                                                                                *ngIf="((submitted || type?.touched) && type?.invalid)">
                                                                                Enter business page type
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 col-xl-4 col-lg-3 mb-2 "
                                                                            *ngIf="(i.type === 'equal') && (i.key == 'totalPage')">
                                                                            <ng-select class="ng-select-line"
                                                                                [items]="userPageTypeArr"
                                                                                bindValue="value" bindLabel="name"
                                                                                placeholder="Select" [clearable]='true'
                                                                                [multiple]="false"
                                                                                [dropdownPosition]="'auto'"
                                                                                [closeOnSelect]="true"
                                                                                name="type{{index}}" #type="ngModel"
                                                                                required [(ngModel)]="i.value"
                                                                                (change)="typeArrChanges(i,'page')">
                                                                            </ng-select>
                                                                            <div class="error-block text-left"
                                                                                *ngIf="((submitted || type?.touched) && type?.invalid)">
                                                                                Select
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 col-xl-4 col-lg-3 mb-2 "
                                                                            *ngIf="(i.type === 'equal') && (i.key == 'paymentFrom')">
                                                                            <ng-select class="ng-select-line"
                                                                                [items]="paymentFromArr"
                                                                                bindValue="value" bindLabel="name"
                                                                                placeholder="Select" [clearable]='true'
                                                                                [dropdownPosition]="'auto'"
                                                                                [closeOnSelect]="true"
                                                                                name="type{{index}}" #type="ngModel"
                                                                                required [(ngModel)]="i.value">
                                                                            </ng-select>
                                                                            <div class="error-block text-left"
                                                                                *ngIf="((submitted || type?.touched) && type?.invalid)">
                                                                                Select
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 col-xl-4 col-lg-3 mb-2 "
                                                                            *ngIf="(i.type === 'equal') && (i.key == 'reqStatus')">
                                                                            <ng-select class="ng-select-line"
                                                                                [items]="requestStatusArr"
                                                                                bindValue="value" bindLabel="name"
                                                                                placeholder="Select" [clearable]='true'
                                                                                [dropdownPosition]="'auto'"
                                                                                [closeOnSelect]="true"
                                                                                name="type{{index}}" #type="ngModel"
                                                                                required [(ngModel)]="i.value">
                                                                            </ng-select>
                                                                            <div class="error-block text-left"
                                                                                *ngIf="((submitted || type?.touched) && type?.invalid)">
                                                                                Select
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 col-xl-4 col-lg-3 mb-2 "
                                                                            *ngIf="(i.type === 'equal') && (i.key == 'isPending')">
                                                                            <ng-select class="ng-select-line"
                                                                                [items]="disputeStatusArray"
                                                                                bindValue="value" bindLabel="name"
                                                                                placeholder="Select" [clearable]='true'
                                                                                [dropdownPosition]="'auto'"
                                                                                [closeOnSelect]="true"
                                                                                name="type{{index}}" #type="ngModel"
                                                                                required [(ngModel)]="i.value">
                                                                            </ng-select>
                                                                            <div class="error-block text-left"
                                                                                *ngIf="((submitted || type?.touched) && type?.invalid)">
                                                                                Select
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 col-xl-4 col-lg-3 mb-2 "
                                                                            *ngIf="(i.type === 'equal') && (i.key == 'subscriptionIds')">
                                                                            <ng-select class="ng-select-line"
                                                                                [items]="subscriptionList"
                                                                                bindValue="_id" bindLabel="name"
                                                                                placeholder="Select" [clearable]='true'
                                                                                [multiple]="true"
                                                                                [dropdownPosition]="'auto'"
                                                                                [closeOnSelect]="true"
                                                                                name="type{{index}}" #type="ngModel"
                                                                                required [(ngModel)]="i.value"
                                                                                (change)="typeArrChanges(i,'page')">
                                                                            </ng-select>
                                                                            <div class="error-block text-left"
                                                                                *ngIf="((submitted || type?.touched) && type?.invalid)">
                                                                                Select
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 col-xl-4 col-lg-3 mb-2 "
                                                                            *ngIf="(i.type === 'contains') && (i.key == 'role')">
                                                                            <ng-select class="ng-select-line"
                                                                                [items]="roleList" bindValue="_id"
                                                                                bindLabel="role" placeholder="Select"
                                                                                [clearable]='true' [multiple]="true"
                                                                                [dropdownPosition]="'auto'"
                                                                                [closeOnSelect]="true"
                                                                                name="type{{index}}" #type="ngModel"
                                                                                required [(ngModel)]="i.value"
                                                                                (change)="typeArrChanges(i,'page')">
                                                                            </ng-select>
                                                                            <div class="error-block text-left"
                                                                                *ngIf="((submitted || type?.touched) && type?.invalid)">
                                                                                Select
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 col-xl-4 col-lg-3 mb-2 "
                                                                            *ngIf="(i.type === 'equal') && (i.key == 'identityType')">
                                                                            <ng-select class="ng-select-line"
                                                                                [items]="identityTypeArr"
                                                                                bindValue="value" bindLabel="name"
                                                                                placeholder="Select" [clearable]='true'
                                                                                [dropdownPosition]="'auto'"
                                                                                [closeOnSelect]="true"
                                                                                name="type{{index}}" #type="ngModel"
                                                                                required [(ngModel)]="i.value">
                                                                            </ng-select>
                                                                            <div class="error-block text-left"
                                                                                *ngIf="((submitted || type?.touched) && type?.invalid)">
                                                                                Select
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row-add">
                                                            <em class="bx bxs-plus-circle text-success"
                                                                (click)="addTask()"></em>
                                                            <em class="bx bxs-minus-circle mr-1 text-danger"
                                                                *ngIf="filteredArr.length > 1"
                                                                (click)="removeTask(index)"></em>
                                                        </div>
                                                    </div>
                                                    <div class="and-or-data justify-content-start">
                                                        <button
                                                            [ngClass]="condition =='$and'?'mr-2 btn btn-primary':'btn btn-outline-primary'"
                                                            (click)="conditionChange('$and')">And</button>
                                                        <button
                                                            [ngClass]="condition =='$or'?'btn btn-primary':'btn btn-outline-primary'"
                                                            (click)="conditionChange('$or')">Or</button>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 mb-2">
                                                    <div class="d-flex align-items-center flex-wrap search-btn-wrap">
                                                        <button type="button" class="btn btn-primary mb-2 mr-2"
                                                            id="button-autoclose1"
                                                            (click)="applyLatestFilter(filterForm)">Search</button>
                                                        <button class="btn btn-outline-primary mb-2 mr-2"
                                                            (click)="clearFilter('htmlclear')"
                                                            id="button-autoclose2">Clear</button>
                                                        <form #descriptionForm="ngForm" class="mr-2">
                                                            <div
                                                                class="form-group d-flex flex-column mx-sm-2 mb-2 group-input">
                                                                <div class="input-group">
                                                                    <input type="text" class="filtersave form-control"
                                                                        placeholder="Enter name to save filter"
                                                                        name="description" #description="ngModel"
                                                                        [(ngModel)]="options.description" fate
                                                                        autocomplete="off" required>
                                                                    <div class="error-block text-left"
                                                                        *ngIf="((submitted1 || description?.touched) && description.invalid)">
                                                                        Enter name
                                                                    </div>
                                                                    <button class="btn btn-primary ml-0"
                                                                        id="button-autoclose3"
                                                                        (click)="toSaveFilter(descriptionForm, options.description, filterColor)">Save
                                                                        & Search</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                        <div class="dropdown colorbox mb-2">
                                                            <button (click)="openCloseColorFilterDropdown()"
                                                                class="btn dropdown-toggle dropdown-btn" type="button"
                                                                data-toggle="dropdown" aria-haspopup="true"
                                                                aria-expanded="false">
                                                                <span id="filterDropdownColor" class="color-box"></span>
                                                            </button>
                                                            <ul class="dropdown-menu dropdown-menu-right dropdown-icon"
                                                                [ngClass]="statusFilterColorPicker ? 'show' : ''"
                                                                aria-labelledby="dropdownMenuButton2">
                                                                <li (click)="selectFilterColor('#FDAC41','yellow')"
                                                                    [ngClass]="('yellow' == this.color) ? 'active' :''">
                                                                    <a class="dropdown-item"><span
                                                                            class="color-shade bg-yellow"></span><span
                                                                            class="color-text">Yellow</span></a>
                                                                </li>
                                                                <li (click)="selectFilterColor('#39DA8A','green')"
                                                                    [ngClass]="('green' == this.color) ? 'active' :''">
                                                                    <a class="dropdown-item"><span
                                                                            class="color-shade bg-green"></span><span
                                                                            class="color-text">Green</span></a>
                                                                </li>
                                                                <li (click)="selectFilterColor('#b87ee5','purple')"
                                                                    [ngClass]="('purple' == this.color) ? 'active' :''">
                                                                    <a class="dropdown-item"><span
                                                                            class="color-shade bg-purple"></span><span
                                                                            class="color-text">Purple</span></a>
                                                                </li>
                                                                <li (click)="selectFilterColor('#FF5B5C','red')"
                                                                    [ngClass]="('red' == this.color) ? 'active' :''">
                                                                    <a class="dropdown-item"><span
                                                                            class="color-shade bg-red"></span><span
                                                                            class="color-text">Red</span></a>
                                                                </li>
                                                                <li (click)="selectFilterColor('#b3c0ce','gray')"
                                                                    [ngClass]="('gray' == this.color) ? 'active' :''">
                                                                    <a class="dropdown-item"><span
                                                                            class="color-shade bg-gray"></span><span
                                                                            class="color-text">Gray</span></a>
                                                                </li>
                                                                <li (click)="selectFilterColor('#00cfdd','blue')"
                                                                    [ngClass]="('blue' == this.color) ? 'active' :''">
                                                                    <a class="dropdown-item"><span
                                                                            class="color-shade bg-skyblue"></span><span
                                                                            class="color-text">Sky
                                                                            Blue</span></a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" sortable-table (sorted)="onSorted($event)"
                class="custom-table table-foruser table table-striped" id="table" aria-describedby="table" width="100%">
                <thead>
                    <tr>
                        <!-- responsive button -->
                        <th [hidden]="!i.isVisible" [sortable-column]="i" [displaySortIcon]="i.sort"
                            *ngFor="let i of tableConfig?.cols" id="th1"
                            [ngClass]="{'text-truncate': i.type == 'html', 'align-center':i.type == 'symbol' }">
                            <ng-container
                                *ngIf="i.type!=='multipleSelection' && i.type!=='switch' && i.type!=='switch_comment' && i.type!='switch_apply' && i.type !='columnSettings' && (i.type !='action')">
                                {{i.colName}}
                            </ng-container>
                            <ng-container
                                *ngIf="(accessPermission.edit || accessPermission.delete ) && (i.type === 'action')">
                                {{i.colName}}
                            </ng-container>
                            <ng-container *ngIf="i.type =='columnSettings' ">
                                <div class=" btn-group" dropdown [insideClick]="true">
                                    <em dropdownToggle class=" fa fa-cog">
                                    </em>
                                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                                        <div class="card">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="row" cdkDropList class="example-list"
                                                        (cdkDropListDropped)="drop($event)">
                                                        <div *ngFor="let setting of colSettingCols" class="example-box"
                                                            cdkDrag
                                                            [ngClass]="(setting.columnVisibility)?'col-md-12':''">
                                                            <ng-container *ngIf="setting.drag">
                                                                <div class="custom-checkbox">
                                                                    <label>
                                                                        <input type="checkbox" name="status"
                                                                            [(ngModel)]="setting.isVisible"
                                                                            [disabled]="setting.disable">
                                                                        <span></span>
                                                                        {{setting.colName}}
                                                                    </label>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                        <div class="text-right filter-btn">
                                                            <div class="col-md-12">
                                                                <button type="submit"
                                                                    class="nav-link btn btn-primary ml-2"
                                                                    (click)="saveColumnSettings(colSettingCols)">Save</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ul>
                                </div>
                            </ng-container>
                            <ng-container
                                *ngIf="i.type=='switch' || i.type == 'switch_comment' || i.type == 'switch_apply'">
                                {{i.colName}}</ng-container>
                            <div *ngIf="i.type=='multipleSelection'" class="custom-checkbox">
                                <label>
                                    <input type="checkbox" name="checkbox" [(ngModel)]="i.checkbox"
                                        (change)="selectAllUser(i)">
                                    <span></span>
                                </label>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container>
                        <tr class="animated fadeIn" *ngFor="let i of tableData?.data;let y=index;">
                            <td [hidden]="!j.isVisible" *ngFor="let j of tableConfig?.cols">
                                <div [ngClass]="j.type=='action'?'d-flex':''">
                                    <div *ngIf="j.type=='multipleSelection'" class="custom-checkbox">
                                        <label><input type="checkbox" name="checkbox" [(ngModel)]="i.checkbox"
                                                (change)="selectMultipleUser(i)"><span></span></label>
                                    </div>
                                    <div *ngIf="j.type=='switch'">
                                        <label class="switch ">
                                            <input type="checkbox" class="success" [(ngModel)]="i[j.colFieldname]"
                                                (click)="changeStatus(i.status,i)" [disabled]="!accessPermission.edit">
                                            <span class="slider round"
                                                [ngStyle]="{'cursor': accessPermission.edit ? 'pointer' : 'default'}"></span>
                                        </label>
                                    </div>
                                    <div *ngIf="j.type=='switch_comment'">
                                        <label class="switch ">
                                            <input type="checkbox" class="success" [(ngModel)]="i.commentDetail.status"
                                                (click)="changeStatus(i.commentDetail.status,i.commentDetail)"
                                                [disabled]="!accessPermission.edit">
                                            <span class="slider round"
                                                [ngStyle]="{'cursor': accessPermission.edit ? 'pointer' : 'default'}"></span>
                                        </label>
                                    </div>
                                    <div *ngIf="j.type == 'switch_apply'">
                                        <ng-select class="ng-select-line select-button" [ngStyle] = "{'cursor': j.disabled ? 'default' : 'pointer'}" [items]="statusList"
                                            bindLabel="name" bindValue="value" placeholder="Select Status"
                                            [clearable]='false' [searchable]='false' [dropdownPosition]="'auto'"
                                            #status="ngModel" [appendTo]="'body'" name="status" [disabled] = "j.disabled"
                                            [(ngModel)]="i[j.colFieldname]" (change)="changeStatus(i.status,i)"
                                            required>
                                        </ng-select>
                                    </div>
                                    <div *ngIf="j.type=='image'">
                                        <div class="td-user-info d-flex align-items-center">
                                            <span class="caption-image mr-2">
                                                <img [src]="i.photo ?  uploadImageUrl + i.photo : 'assets/images/NoProfile.png'"
                                                    alt="no img" (error)="img.src = 'assets/images/NoProfile.png'"
                                                    #img></span>
                                            <div class="name-wrap">
                                                <strong> {{i[j.subColFieldName1]}}
                                                    {{i[j.subColFieldName2]}}</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="j.type=='action'">
                                        <div *ngFor="let k of tableConfig?.actions" class="action-icon">
                                            <ng-container *ngIf="k.type === 'icon'">
                                                <span class="edit-delet-icon pointer" *ngIf="k.permission">
                                                    <em [class]="k.class" aria-hidden="true" [tooltip]="k.tooltip"
                                                        (click)="performAction(k,i)">
                                                    </em>
                                                </span>
                                            </ng-container>
                                            <div *ngIf="k.type === 'button'">
                                                <ng-container *ngIf="k.permission">
                                                    <button type="button" [class]="k.class"
                                                        (click)="performAction(k,i)">{{k.buttonTitle}}</button>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <ng-conatiner *ngIf="(i.status != 'Pending') && j.action_color"
                                            class="status-wrap">
                                            <p class="text-success" *ngIf="i.status == 'Accepted'">Accepted</p>
                                            <p class="text-danger" *ngIf="i.status == 'Rejected'">Rejected</p>
                                            <p class="pointer text-primary" (click)="viewRequestDetails(i,j.subtype)">
                                                View Details
                                            </p>
                                        </ng-conatiner>
                                        <div class="check-delet" *ngIf="(i.status == 'Pending') && j.action_color">
                                            <i class='bx bx-check pointer'
                                                (click)="reportModel('Accepted',i,j.subtype)"></i>
                                            <i class='bx bx-x pointer'
                                                (click)="reportModel('Rejected',i,j.subtype)"></i>
                                        </div>
                                    </ng-container>
                                    <div *ngIf="j.type =='symbol'" class="align-center">
                                        <span *ngIf="i[j.colFieldname] === true">&#10003;</span>
                                        <span *ngIf="i[j.colFieldname] === false">&#9747;</span>
                                    </div>
                                    <div *ngIf="j.type == 'addIcon'" [routerLink]="[this.URLConstants.BLOG_ADD]"
                                        class="addIcon">
                                        <span class='bx bxs-plus-circle'></span>
                                    </div>
                                    <div *ngIf="j.type == 'id'">
                                        {{y+1}}
                                    </div>
                                    <div *ngIf="j.type == 'subscription'">
                                        {{(i.subscriptionPackage) ? (i.subscriptionPackage) : '--'}}<br>
                                    </div>
                                    <div *ngIf="j.type == 'date_time'">
                                        {{i[j.colFieldname] ? (i[j.colFieldname] | date:'dd/MM/yyy') : '--'}}<br>
                                        {{i[j.colFieldname] ? (i[j.colFieldname] | date:'hh:mm a') : '--'}}
                                    </div>
                                    <div *ngIf="j.type == 'date'">
                                        {{i[j.colFieldname] ? (i[j.colFieldname] | date:'dd/MM/yyy') : '--'}}
                                    </div>
                                    <div *ngIf="j.type == 'userInfo'">
                                        <div class="td-user-info d-flex">
                                            <div class="thumb-img mr-2">
                                                <span class="caption-image">
                                                    <img [src]="i.photo ?  uploadImageUrl + i.photo : 'assets/images/NoProfile.png'"
                                                        alt="no img" (error)="img.src = 'assets/images/NoProfile.png'"
                                                        #img></span>
                                            </div>
                                            <div class="name-wrap">
                                                <strong> {{(i.firstName)}} {{i.lastName}}</strong><br>
                                                {{i.emailId}} <br>
                                                {{i.mobile}}
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="j.type == 'pageInfo'">
                                        <div class="td-user-info d-flex">
                                            <div class="thumb-img mr-2" *ngIf="j.pageimg">
                                                <span class="caption-image">
                                                    <img [src]="i.logo ?  uploadImageUrl + i.logo : 'assets/images/NoProfile.png'"
                                                        alt="no img" (error)="img.src = 'assets/images/NoProfile.png'"
                                                        #img></span>
                                            </div>
                                            <div class="thumb-img mr-2" *ngIf="j.img">
                                                <span class="caption-image">
                                                    <img [src]="i.photo ?  uploadImageUrl + i.photo : 'assets/images/NoProfile.png'"
                                                        alt="no img" (error)="img.src = 'assets/images/NoProfile.png'"
                                                        #img></span>
                                            </div>
                                            <div class="thumb-img mr-2" *ngIf="j.invImg">
                                                <span class="caption-image">
                                                    <img [src]="i.identityLogo ?  uploadImageUrl + i.identityLogo : 'assets/images/NoProfile.png'"
                                                        alt="no img" (error)="img.src = 'assets/images/NoProfile.png'"
                                                        #img></span>
                                            </div>
                                            <div class="thumb-img mr-2" *ngIf="j.identity">
                                                <span class="caption-image">
                                                    <img [src]="(i.identityInfo.photo) ?  uploadImageUrl + (i.identityInfo.photo) : 'assets/images/NoProfile.png'"
                                                        alt="no img" (error)="img.src = 'assets/images/NoProfile.png'"
                                                        #img></span>
                                            </div>
                                            <div class="name-wrap" *ngIf="!j.identity">
                                                <strong> {{i[j.colFieldname]}}</strong><br>
                                            </div>
                                            <div class="name-wrap" *ngIf="j.identity">
                                                <strong> {{i.identityInfo.name}}</strong><br>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="j.type == 'rounds'">
                                        <div class="td-user-info">
                                            <ng-container *ngFor="let round of i.rounds">
                                                <p>{{round.stage}} - ${{round.amount | shortNumber}}</p>
                                                <div class="user-info-date">{{round.investmentdate | date:'dd/MM/yyy'}}
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div *ngIf="j.type == 'users'">
                                        <div class="td-user-info d-flex">
                                            <div class="thumb-img mr-2">
                                                <span class="caption-image">
                                                    <img [src]="(i[j.colTest].photo) ?  uploadImageUrl + (i[j.colTest].photo) : 'assets/images/NoProfile.png'"
                                                        alt="no img" (error)="img.src = 'assets/images/NoProfile.png'"
                                                        #img></span>
                                            </div>
                                            <div class="name-wrap">
                                                <strong> {{i[j.colTest].firstName}}</strong><br>
                                            </div>
                                            <div class="name-wrap">
                                                <strong> {{i[j.colTest].lastName}}</strong><br>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="j.type == 'comentInfo'">
                                        <div class="td-user-info d-flex">
                                            <div class="thumb-img mr-2">
                                                <span class="caption-image">
                                                    <img [src]="(i[j.colTest].userInfo?.photo) ?  (uploadImageUrl + (i[j.colTest].userInfo?.photo)) : 'assets/images/NoProfile.png'"
                                                        alt="no img" (error)="img.src = 'assets/images/NoProfile.png'"
                                                        #img></span>
                                            </div>
                                            <div class="name-wrap" (click)="viewComments(i)">
                                                <strong style="color:blue;cursor: pointer;">
                                                    {{i[j.colTest].userInfo?.name}}</strong><br>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="j.type == 'disputeInfo'">
                                        <div class="td-user-info d-flex">
                                            <div class="thumb-img mr-2">
                                                <span class="caption-image">
                                                    <img [src]="i[j.colFieldname].logo ?  uploadImageUrl + i[j.colFieldname].logo : 'assets/images/NoProfile.png'"
                                                        alt="no img" (error)="img.src = 'assets/images/NoProfile.png'"
                                                        #img></span>
                                            </div>
                                            <div class="name-wrap">
                                                <strong style="color:blue;cursor: pointer;"
                                                    (click)="goToDetails(i[j.colFieldname].identityId, i[j.colFieldname].identityType, i[j.colFieldname])">
                                                    {{i[j.colFieldname].name}}</strong><br>
                                                {{i[j.colFieldname].entity}}<br>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="j.type=='disputeTitle'" style="cursor: pointer;color: blue"
                                        (click)="gotoDisputeDetail(i._id)">
                                        {{i[j.colFieldname]}}
                                    </div>
                                    <div *ngIf="j.type=='disputeStatus'">
                                        {{i[j.colFieldname] == true ? 'Open' : 'Resolved'}}
                                    </div>
                                    <div *ngIf="j.type=='disputeDesc'">
                                        <div style="cursor: pointer;" [line-truncation]="3"
                                            (click)="setDisableEnable(y, i.isTruncated)" [disabled]="i.isTruncated">
                                            {{i[j.colFieldname]}}
                                        </div>
                                    </div>
                                    <div *ngIf="j.type == 'comentdetail'">
                                        {{i[j.colTest].comment}}
                                    </div>
                                    <div *ngIf="j.type == 'noType'">
                                        <span class="page-no-type" [tooltip]="tolTemplate">{{i[j.colFieldname]}}</span>
                                        <ng-template #tolTemplate>
                                            Investor: {{i.investorPageCount}} <br>
                                            Startup: {{i.startupPageCount}} <br>
                                            Service Provider: {{i.serviceProviderPageCount}}
                                        </ng-template>
                                    </div>
                                    <div *ngIf="j.type=='text_url'">
                                        <a href="javascript:;" style="cursor: pointer;color:#1a3992"
                                            (click)="i[j.colFieldname] ? gotoURL(i[j.colFieldname]): ''">
                                            {{i[j.colFieldname] ?
                                            i[j.colFieldname] :
                                            '--'}}</a>
                                    </div>
                                    <div *ngIf="j.type=='text'">
                                        {{j.isObj ? i[j.colFieldname][j.isObj] : (j.isDate ? (i[j.colFieldname] |
                                        date:'MM/dd/yyyy') : i[j.colFieldname] ? i[j.colFieldname] :
                                        (i[j.colFieldname] == '0') ?
                                        i[j.colFieldname]:'--')}}
                                        <div *ngIf=" j.subColFieldName1 || j.subColFieldName2">
                                            <span *ngIf="j.subColFieldName1">{{i[j.subColFieldName1]}}</span> &nbsp;
                                            <span *ngIf="j.subColFieldName2">{{i[j.subColFieldName2]}}</span>
                                        </div>
                                        <div *ngIf="!i.isSetPassword && j.reqSent">
                                            <p style="color: darkgreen;
                                            font-weight: 500;">Request Sent</p>
                                        </div>
                                    </div>
                                    <div *ngIf="j.type == 'identityType'">
                                        <ng-container *ngIf="i[j.colFieldname] == 'individual'">Individual
                                        </ng-container>
                                        <ng-container *ngIf="i[j.colFieldname] == 'startup'">Startup</ng-container>
                                        <ng-container *ngIf="i[j.colFieldname] == 'serviceProvider'">Service Provider
                                        </ng-container>
                                        <ng-container *ngIf="i[j.colFieldname] == 'investor'">Investor</ng-container>
                                    </div>
                                    <div *ngIf="j.type == 'inputtext'">
                                        <ng-container *ngIf="!inputTextPrice[y]">
                                            {{((type=='subscriptionListing') || (type == 'creditListing')) ? '$' :
                                            ''}}{{i[j.colFieldname]}}
                                            <span aria-hidden="true" *ngIf="j.edit" tooltip="Edit" placement="bottom"
                                                (click)="changeToInputText(y,'price',i[j.colFieldname])"
                                                class="bx bx-edit text-primary cursor-pointer ml-2"></span>
                                        </ng-container>
                                        <ng-container *ngIf="inputTextPrice[y]" class="1">
                                            <div class="price-edit-box">
                                                <input type="text" [(ngModel)]="price" class="form-control"
                                                    (keydown)="restrictKeyPressService.AllowNumbers($event)" />
                                                <span aria-hidden="true" tooltip="Update" placement="bottom"
                                                    (click)="updateInputText(price,i,y,j.colFieldname,'price')"
                                                    class="bx bx-check text-primary cursor-pointer ml-2 price-close"></span>
                                                <span aria-hidden="true" tooltip="Cancel" placement="bottom"
                                                    (click)="changeToInputText(y,'price',price)"
                                                    class="bx bx-x text-primary cursor-pointer ml-2 price-done"></span>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div *ngIf="j.type == 'text_price'">
                                        ${{i[j.colFieldname]}}
                                    </div>
                                    <div *ngIf="j.type == 'text_credit'">
                                        {{i[j.colFieldname]}} {{i[j.colFieldname] > 1 ? 'credits' : 'credit'}}
                                    </div>
                                    <div *ngIf="j.type == 'text_status'" style="text-transform:capitalize">
                                        {{i[j.colFieldname]}}
                                    </div>
                                    <div *ngIf="j.type == 'trnUserInfo'">
                                        <div class="td-user-info d-flex">
                                            <div class="thumb-img mr-2">
                                                <span class="caption-image">
                                                    <img [src]="(i[j.colPhoto]) ?  (uploadImageUrl + (i[j.colPhoto])) : 'assets/images/NoProfile.png'"
                                                        alt="no img" (error)="imgTU.src = 'assets/images/NoProfile.png'"
                                                        #imgTU></span>
                                            </div>
                                            <div class="name-wrap" (click)="gotoUserData(i)">
                                                <strong style="color:blue;cursor: pointer;">
                                                    {{i[j.colFieldname]}}</strong><br>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="j.type == 'trn_details'">
                                        <div class="td-user-info d-flex">
                                            <ng-container
                                                *ngIf="(i[j.colFieldname] == creditSubType.applyJob) || (i[j.colFieldname] == creditSubType.promoteJob)">
                                                {{i.jobDetail?.title}}
                                            </ng-container>
                                            <ng-container *ngIf="(i[j.colFieldname] == creditSubType.promotionCost)">
                                                {{i.additional_promotion_cost}}
                                            </ng-container>
                                            <ng-container *ngIf="(i[j.colFieldname] == creditSubType.promotePage)">
                                                <div class="td-user-info d-flex">
                                                    <div class="thumb-img mr-2">
                                                        <span class="caption-image">
                                                            <img [src]="(i.pageDetail.logo) ?  (uploadImageUrl + (i.pageDetail.logo)) : 'assets/images/NoProfile.png'"
                                                                alt="no img"
                                                                (error)="imgP.src = 'assets/images/NoProfile.png'"
                                                                #imgP></span>
                                                    </div>
                                                    <div class="name-wrap">
                                                        <strong>
                                                            {{i.pageDetail.name}}</strong><br>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="(i[j.colFieldname] == creditSubType.promoteVideo)">
                                                <div class="video-box">
                                                    <img [src]="(i.videoDetail?.videoThumb) ? (uploadImageUrl + (i.videoDetail?.videoThumb)) : 'assets/images/NoProfile.png'"
                                                        (error)="imgV.src = 'assets/images/NoProfile.png'" #imgV
                                                        alt="no img" width="450" height="300">
                                                    <div class="position-ab">
                                                        <div class="play-icon" (click)="openVideo(i.videoDetail)">
                                                            <span class='bx bx-play'></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div *ngIf="j.type == 'inputtextprice'">
                                        <ng-container *ngIf="!inputTestPriceCredit[y]">
                                            {{i[j.colFieldname]}}
                                            <span aria-hidden="true" tooltip="Edit" placement="bottom"
                                                (click)="changeToInputText(y,'pricecredit',i[j.colFieldname])"
                                                class="bx bx-edit text-primary cursor-pointer ml-2"></span>
                                        </ng-container>
                                        <ng-container *ngIf="inputTestPriceCredit[y]">
                                            <input type="text" [(ngModel)]="pricepromotional"
                                                (keydown)="restrictKeyPressService.AllowNumbers($event)" />
                                            <span aria-hidden="true" tooltip="Update" placement="bottom"
                                                (click)="updateInputText(pricepromotional,i,y,'creditsAfterLimit','pricecredit')"
                                                class="bx bx-check text-primary cursor-pointer ml-2"></span>
                                            <span aria-hidden="true" tooltip="Update" placement="bottom"
                                                (click)="changeToInputText(y,'pricecredit',pricepromotional)"
                                                class="bx bx-x text-primary cursor-pointer ml-2"></span>
                                        </ng-container>
                                    </div>
                                    <div *ngIf="j.type == 'text_user'" style="color: blue;cursor: pointer;"
                                        (click)="gotoDetail(i)">
                                        {{i.createdBy}}
                                    </div>
                                    <div *ngIf="j.type == 'roleName'" >
                                        {{i.roleName}}
                                    </div>
                                    <div *ngIf="j.type == 'documents'" class="documents-types">
                                        <ng-container *ngIf="i.attachments.length>0">
                                            <ng-container *ngFor="let doc of i.attachments">
                                                <div class="d-flex documents-types-single align-items-center"
                                                    style="cursor: pointer;" (click)="gotoURL(doc.path,'download')">
                                                    <i class='bx bx-file-blank pr-2'></i>
                                                    {{doc.path}}
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="i.attachments.length == 0">
                                            --
                                        </ng-container>
                                    </div>
                                    <div *ngIf="j.type=='longDesc'">
                                        {{i.description | slice : 0 : 50}}
                                        <a *ngIf="i.description.length > 50" [outsideClick]="true"
                                            popover="{{i.description}}" placement="bottom"><strong>Read
                                                More </strong></a>
                                    </div>
                                    <div *ngIf="j.type=='role'">
                                        <ng-container *ngIf="i.readPermissions && j.subType === 'read'">
                                            <ng-container
                                                *ngTemplateOutlet="roleTemplate;context:{moduleArray: i.readPermissions}">
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="i.editPermissions && j.subType === 'edit'">
                                            <ng-container
                                                *ngTemplateOutlet="roleTemplate;context:{moduleArray: i.editPermissions}">
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="i.createPermissions && j.subType === 'create'">
                                            <ng-container
                                                *ngTemplateOutlet="roleTemplate;context:{moduleArray: i.createPermissions}">
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="i.deletePermissions && j.subType === 'delete'">
                                            <ng-container
                                                *ngTemplateOutlet="roleTemplate;context:{moduleArray: i.deletePermissions}">
                                            </ng-container>
                                        </ng-container>
                                        <ng-template #roleTemplate let-moduleArray="moduleArray">
                                            <div [attr.title]="i.isTruncated ? 'View less' : 'View more'"
                                                style="cursor: pointer;" [line-truncation]="3"
                                                (click)="setDisableEnable(y, i.isTruncated)" [disabled]="i.isTruncated"
                                                *ngIf="moduleArray.length > 0">
                                                <div *ngFor="let role of roleCategory | keyvalue">
                                                    <div *ngFor="let module of moduleArray; let i = index">
                                                        <span class="badge badge-secondary" *ngIf="module === role.key">
                                                            {{role.value}}
                                                            <!-- <span *ngIf="i<(moduleArray.length-1)">,</span> -->
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="moduleArray.length === 0"> - </div>
                                        </ng-template>
                                    </div>
                                    <div *ngIf="j.type=='html'" [innerHTML]="i[j.colFieldname]"></div>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
            <!-- Data Table End -->
        </div>
        <!-- TABLE FOOTER START-->
        <div class="card-footer" *ngIf="this.conditions.showTableFooter">
            <div class="custom-pagination d-flex align-items-center justify-content-between" *ngIf="tableLength">
                <div class="d-flex align-items-center">
                    <span class="record-count mr-1">Showing</span>
                    <ng-select class="ng-select-line" (change)="selectItemPerPage(itemsPerPage)"
                        [items]="itemsPerPageList" placeholder="Select" [(ngModel)]="itemsPerPage" [clearable]='false'
                        [searchable]='false' [dropdownPosition]="'top'" [labelForId]="tableData.type">
                    </ng-select>
                    <span class="record-count ml-2">out of {{total}} entries</span>
                </div>
                <div class="pagination-list">
                    <pagination [itemsPerPage]='itemsPerPage' #customPagination [totalItems]="total"
                        [boundaryLinks]="true" (pageChanged)="pagination($event)" [maxSize]="5" previousText="Prev"
                        nextText="Next" firstText="&laquo;" lastText="&raquo;">
                    </pagination>
                </div>
            </div>
            <!-- Pagination End -->
        </div>
        <!-- TABLE FOOTER END-->
    </div>
</div>