import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

// Auth Guard
import { CanLoginActivate, CanAuthActivate } from "./common/service/auth-guard";

// Component
import { MainComponent } from './modules/main/main.component';
import { LoginComponent } from "./public/login/login.component";
import { ForgotPasswordComponent } from "./public/forgot-password/forgot-password.component";
import { SocialLoginComponent } from "./public/social-login/social-login.component";
import { ResetPasswordComponent } from "./public/reset-password/reset-password.component";
import { MasterComponent } from './modules/master/master.component';

// plugin
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

// module
import { SharedModule } from './shared/shared.module';


// Service

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: "login",
    canActivate: [CanLoginActivate],
    component: LoginComponent,
    pathMatch: "full",
  },
  {
    path: "forgot-password",
    canActivate: [CanLoginActivate],
    component: ForgotPasswordComponent,
    pathMatch: "full",
  },
  {
    path: "reset-password",
    canActivate: [CanLoginActivate],
    component: ResetPasswordComponent,
    pathMatch: "full",
  },
  {
    path: "social-login",
    canActivate: [CanLoginActivate],
    component: SocialLoginComponent,
    pathMatch: "full",
  },
  {
    path: '',
    canActivate: [CanAuthActivate],
    component: MainComponent,
    children: [
      {
        path: 'master', canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/master/master.module").then(
            (mod) => mod.MasterModule
          )
      },

      {
        path: "dashboard",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/dashboard/dashboard.module").then(
            (mod) => mod.DashboardModule
          )
      },
      {
        path: "users",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/user-management/users/users.module").then(
            (mod) => mod.UsersModule
          ),
      },
      {
        path: "my-pages",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/my-pages/my-pages.module").then(
            (mod) => mod.MyPagesModule
          ),
      },
      {
        path: 'change-password',
        loadChildren: () => import('./modules/change-password/change-password.module')
          .then((m) => m.ChangePasswordModule),
      },
      {
        path: 'my-profile',
        loadChildren: () => import('./modules/my-profile/my-profile.module')
          .then((m) => m.MyProfileModule),
      },
      {
        path: 'subscriptions',
        canActivate: [CanAuthActivate],
        loadChildren: () => import('./modules/subscriptions/subscriptions.module')
          .then((m) => m.SubscriptionsModule),
      },
      {
        path: 'reports',
        canActivate: [CanAuthActivate],
        loadChildren: () => import('./modules/reports/report.module')
          .then((m) => m.ReportModule),
      },
      {
        path: 'dispute-request',
        loadChildren: () => import('./modules/dispute-request/dispute-request.module')
          .then((m) => m.DisputeRequestModule),
      },
      {
        path: 'feed-videos',
        loadChildren: () => import('./modules/feed-videos/feed-videos.module')
          .then((m) => m.FeedVideosModule),
      },
      {
        path: 'feed-comments',
        loadChildren: () => import('./modules/feed-comments/feed-comments.module')
          .then((m) => m.FeedCommentsModule),
      },
      {
        path: 'feed-pages',
        loadChildren: () => import('./modules/feed-pages/feed-pages.module')
          .then((m) => m.FeedPagesModule),
      },
      {
        path: 'investor-request',
        loadChildren: () => import('./modules/accreditation-requests/accreditation-requests.module')
          .then((m) => m.AccreditationRequestsModule),
      },
      {
        path: 'identity-request',
        loadChildren: () => import('./modules/identity-requests/identity-requests.module')
          .then((m) => m.IdentityRequestsModule),
      },
      {
        path: "content",
        loadChildren: () =>
          import("./modules/content-management/content-management.module").then(
            (m) => m.ContentManagementModule
          ),
      },
      {
        path: 'email-template',
        // canActivate: [CanViewListActivate],
        data: { role: 'EmailTemplate' },
        loadChildren: () => import('./modules/email-management/email-templates/email-templates.module')
          .then((m) => m.EmailTemplatesModule),
      },
      {
        path: 'manage-roles',
        loadChildren: () => import('./modules/manage-roles/manage-roles.module')
          .then((m) => m.ManageRolesModule),
      },
      {
        path: 'manage-sub-admin',
        loadChildren: () => import('./modules/manage-sub-admin/manage-sub-admin.module')
          .then((m) => m.ManageSubAdminModule),
      },
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },

];

@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SocialLoginComponent,
    MainComponent,
    MasterComponent,
  ],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    NgOtpInputModule,
    NgxIntlTelInputModule,
    RouterModule.forRoot(routes, { useHash: true }),
  ],
  exports: [RouterModule],
  providers: [CanLoginActivate],
})
export class AppRoutingModule { }
