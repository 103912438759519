export const messages = {
    ERROR: {
        REQUIRED: {
            Email: 'Enter email address',
            Password: 'Enter password',
            Username: 'Enter username',
            currentPassword: 'Enter current password',
            newPassword: 'Enter new password',
            confirmPassword: 'Enter confirm password',
            firstname: 'Enter first name',
            lastname: 'Enter last name',
            firstName: 'Enter first name',
            lastName: 'Enter last name',
            userName: 'Enter user name',
            companyName: 'Enter company name',
            mobile: 'Enter mobile number',
            emailId: 'Enter email',
            roleName: 'Enter role',
            role: 'Enter role',
            PhoneNo: ' Enter phone number',
            Amount: ' Enter amount',
            filterName: 'Enter name of filter',
            emailKey: 'Please enter title',
            subject: 'Please enter subject',
            fromEmailId: 'Please enter email',
            tag: 'please add tag',
            emailTemplateId: 'Select event',
            host: 'Enter host',
            port: 'Enter port',
            category: 'Enter blog category',
            description: 'Enter description',
            categoryName: 'Select category',
            faqsCategory: 'Enter category',
            answer: 'Enter answer',
            question: 'Enter question',
            image: 'select image',
            caption: 'caption',

            blogTitle: 'Enter blog title',
            slug: 'Enter slug',
            defaultFromEmail: 'Enter default from email',
            defaultAdminEmail: 'Enter default admin email',
            metaTitle: 'Enter meta title',
            metaDescription: 'Enter meta description',
            metaKeyword: 'Enter meta keywords',
            pageTitle: 'Enter page title',
            currency: 'Select currency',
            mainCurrency: 'Enter currency',
            dateFormat: 'Select date format',
            templateName: 'Enter template name',
            countryName: 'Enter country name',
            countryId: 'Enter country name',
            countryCode: 'Enter country code',
            phoneCode: 'Enter phone code',
            facebookAppId: 'Enter facebook app id',
            googleToken: 'Enter google OAuth Token',
            pKey: 'Enter publishable key',
            sKey: 'Enter secret key',
            mode: 'Enter mode',
            clientSecret: 'Enter client secret',
            clientId: 'Enter client id',
            timezone: 'Enter timezone',
            twitterLink: 'Enter twitter link',
            facebookLink: 'Enter facebook link',
            googleLink: 'Enter google link',
            linkedinLink: 'Enter linkedin link',
            instagramLink: 'Enter instagram link',
            websiteLink: 'Enter website link',
            dob: 'Select date of birth',
            country: 'Select country',
            address: 'Enter address',
            languages: 'Select language',
            footerSnippet: 'Enter footer snippet',
            headerSnippet: 'Enter header snippet',
            analyticsSnippet: 'Enter analytics snippet',
            authToken: 'Enter auth token',
            accountSid: 'Enter Account SID',
            // description: 'Enter Description'
            comment: 'Enter comment',
            consumerPercent: 'Enter %',
            providerPercent: 'Enter %',
            subscriptionId: 'Select Subscription',
            subscriptionDurationFromAdmin: 'Select Duration'
        },
        PATTERN: {
            Email: 'Enter valid email address',
            Contactno: 'Invalid contact number',
            emailId: 'Enter valid email',
            fromEmailId: 'Enter valid email',
            adminEmail: 'Enter valid email',
            newPassword: 'Enter atleast 8 characters with one uppercase, one digit and one special character',
            confirmPassword: 'Enter valid password',
            link: 'url should contain https://',
            mobile: 'Enter valid mobile number (include "+" and country code)'
        },
        MINLENGTH: {
            answer: 'Answer must be minimum 20 characters.',
            question: 'Question must be minimum 10 characters.',
            description: 'Blog description should be minimum 20 characters',
            category: 'Blog category should be minimum 2 characters',
            mobile: 'Mobile Number should be 10 digits',
            PhoneNo: 'Mobile Number should be 10 digits',
            confirmPassword: 'Require atleast 6 characters',
            newPassword: 'Require atleast 6 characters',
            port: 'Minimum 3 didgit required',
            firstname: 'Firstname should be in 2-20 characters',
            lastname: 'Lastname should be in 2-20 characters'
        },
        CUSTOM: {
            ConfirmPassword: 'Confirm password does not match!',
        },
        MAXLENGTH: {
            firstname: 'Firstname should be in 2-20 characters',
            lastname: 'Lastname should be in 2-20 characters'
        }
    },
};
