<div class="theme-setting-block" [ngClass]="{'open': isSettingOpen}">
    <a class="theme-setting-link" (click)="openSettingBox()">
        <em class="bx bx-cog bx-flip-horizontal" [ngClass]="{'bx-spin': !isSettingOpen}"></em>
    </a>
    <div class="d-flex align-items-center">
        <span class="light-icon icon mr-1 d-block"><em class="bx bx-sun"></em></span>
        <div class="custom-control custom-switch theme-switch">
            <input type="checkbox" class="custom-control-input" id="resetPassSwitchTheme" (click)="toggleTheme()">
            <label class="custom-control-label" for="resetPassSwitchTheme"></label>
        </div>
        <span class="dark-icon icon"><em class="bx bxs-sun"></em></span>
    </div>
</div>

<!--Start Login Wrapper-->
<div class="login-wrapper"
    style="background:url(assets/images/login-bg.jpg) no-repeat center center; background-size: cover;">
    <div class="login-body">
        <section class="login-container row m-0">
            <div class="col-xl-8 col-11">
                <div class="card bg-login-card">
                    <div class="row m-0">
                        <div class="col-md-6 col-12 pl-0 pr-0">
                            <div class="card rounded-left mb-0 p-4 d-flex justify-content-center h-100">
                                <div class="card-header">
                                    <img src="assets/images/logo.png" style="margin-bottom: 20px;" alt="GitFunded"
                                        title="GitFunded"><br>
                                    <h4 style="text-align: center;">Super Admin</h4><br>
                                    <div class="card-title text-center mb-4">
                                        <h3 class="mb-2 mt-2">{{ (type == 'reset') ? 'Reset your password' : 'Set your
                                            password'}}
                                        </h3>
                                    </div>
                                </div>
                                <div class="card-content">
                                    <div class="card-body">
                                        <div class="text-center mb-5">
                                            <p>You have successfully verified
                                                your account.<br>
                                                Enter new password
                                                below.
                                            </p>
                                        </div>
                                        <form #resetPassForm="ngForm"
                                            (ngSubmit)="resetPassForm.form.valid && onResetPassword(resetPassForm)">
                                            <div class="form-group mb-4 position-relative">
                                                <label>New Password<sup class="bx bxs-star danger"></sup></label>
                                                <input [type]="fieldTextType ? 'text' : 'password'" ngModel
                                                    id="password-field" type="password" name="password" minlength="8"
                                                    maxlength="16" class="form-control" #password="ngModel"
                                                    placeholder="Password"
                                                    (keydown)="restrictKeyPressService.RestrictSpace($event)"
                                                    [ngClass]="{ 'is-invalid':(resetPassForm.submitted  || password.touched) && password.invalid }"
                                                    required
                                                    pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$">
                                                <span toggle="#password-field"
                                                    [tooltip]="fieldTextType ? 'Hide Password' : 'Show Password'"
                                                    class="bx bx-hide field-icon toggle-password" [ngClass]="{
                                                    'fa-eye-slash': !fieldTextType,
                                                    'fa-eye': fieldTextType
                                                  }" (click)="toggleFieldTextType()"></span>
                                                <div *ngIf="(resetPassForm.submitted  || password.touched) && password.invalid"
                                                    class="invalid-feedback">
                                                    <div *ngIf="password.errors.required">Please enter password</div>
                                                    <div
                                                        *ngIf="password.errors.pattern || password.errors.minlength || password.errors.maxlength">
                                                        Password should contain atleast
                                                        a special character (@, $, !, %, *, ?, & or #), a capital letter
                                                        and a number and length should be minimum 8-16 characters</div>
                                                </div>
                                            </div>
                                            <button class="btn btn-primary glow position-relative btn-block"
                                                type="submit">
                                                {{ (type =='reset') ? 'Reset Password' : 'Set Password'}}<em
                                                    class="icon-arrow bx bx-right-arrow-alt"></em>
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 d-md-block d-none text-center align-self-center p-7">
                            <div class="card-content">
                                <picture>
                                    <source srcset="assets/images/forgot-password.webp" type="image/webp">
                                    <source srcset="assets/images/forgot-password.png" type="image/png">
                                    <img class="img-fluid" src="assets/images/forgot-password.png" alt="forgot-password"
                                        width="300">
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
<!--End Login Wrapper-->