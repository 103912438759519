import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/common/service/common.service';
import { callAPIConstants } from '../../../../common/constants/callAPI-constants';
import { URLConstants } from '../../../../common/constants/routerLink-constants';
@Component({
  selector: 'app-review-comment',
  templateUrl: './review-comment.component.html',
  styleUrls: []
})
export class ReviewCommentComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  public URLConstants = URLConstants;
  public comment: any = {};
  public type;
  public identityId;
  public identityType;
  public subtype;
  public submitted: boolean = false;
  constructor(public commonService: CommonService, public modalRef: BsModalRef, public modalService: BsModalService) { }

  ngOnInit(): void {
    if (this.subtype == 'accreditation' && this.type == 'Accepted') {
      let today = new Date();
      this.comment.verificationExpiryDate = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());
    }
  }
  reportData(form, data) {
    if (form.valid) {
      data['status'] = this.type
      data['identityId'] = this.identityId
      data['identityType'] = this.identityType
      if (this.subtype == 'accreditation' && this.type == 'Accepted') {
        data['verificationExpiryDate'] = this.comment.verificationExpiryDate.toISOString();
      }
      const method = (this.subtype == 'accreditation') ? this.callAPIConstants.ReportAccreditationRequest : this.callAPIConstants.ReportIdentityRequest
      this.commonService.callApi(method, data, 'put', false, false).then((success) => {
        if (success.status == 1) {
          this.modalRef.hide()
          this.modalService.onHidden.emit({ done: true });
        }
      })

    } else {
      this.submitted = true;
    }
  }
}
