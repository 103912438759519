<div class="modal-wrap">
    <h5>{{isResolved ? 'Mark Dispute As Resolved' : 'Add Comment'}}</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
    <hr>
    <form #form="ngForm" (ngSubmit)="submit(form)" class="mark-as-form">
        <div class="form-group" [ngClass]="((submitted || comment.touched) && comment.invalid) ? 'has-danger' : ''">
            <label>{{isResolved ? 'Final Comment' : 'Add Comment'}}</label>
            <textarea class="w-100" placeholder="Type your comments here." [(ngModel)]="data.comment" #comment="ngModel"
                maxlength="3000" required></textarea>
            <div class="error-block text-left" style="color:red"
                *ngIf="(((submitted || comment.touched) && comment.invalid))">
                {{showErrorService.showError('comment', comment.errors)}}
            </div>
            <div class="error-block text-left" style="color:red" *ngIf="((comment.value && !comment.value.trim()))">
                Enter valid comment
            </div>
        </div>
        <div class="row" *ngIf="isResolved">
            <div class="col-md-6">
                <label style="text-transform: lowercase !important">% of fund transffered to Consumer</label>
                <input type="text" placeholder="e.g. 100" maxlength="3" [(ngModel)]="data.consumerPercent"
                    #consumerPercent="ngModel" [required]="isResolved"
                    (keydown)="restrictKeyPressService.AllowNumbers($event)"><span class="per">%</span>
                <div class="error-block text-left" style="color:red"
                    *ngIf="((submitted || consumerPercent.touched) && consumerPercent.invalid)">
                    {{showErrorService.showError('consumerPercent', consumerPercent.errors)}}
                </div>
            </div>
            <div class="col-md-6">
                <label style="text-transform: lowercase !important">% of fund transffered to Service Provider</label>
                <input type="text" placeholder="e.g. 100" maxlength="3" max="100" [(ngModel)]="data.providerPercent"
                    #providerPercent="ngModel" [required]="isResolved"
                    (keydown)="restrictKeyPressService.AllowNumbers($event)"><span class="per">%</span>
                <div class="error-block text-left" style="color:red"
                    *ngIf="((submitted || providerPercent.touched) && providerPercent.invalid)">
                    {{showErrorService.showError('providerPercent', providerPercent.errors)}}
                </div>
            </div>
            <div class="error-block text-left" style="color:red; padding-left: 150px;"
                *ngIf="((data.consumerPercent) && (data.providerPercent)) && (((+data.consumerPercent) + (+data.providerPercent)) != 100)">
                Enter valid percentage
            </div>
        </div>
        <div class="btn-wrap mark-btn mt-6 text-center">
            <button class="btn btn-primary btn-lg">{{isResolved ? 'Mark Dispute As Resolved' : 'Add Comment'}}</button>
        </div>
    </form>
</div>