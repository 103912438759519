import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// constants
import { callAPIConstants } from '../constants/callAPI-constants';
import { URLConstants } from '../constants/routerLink-constants';

// service
import { CommonService } from './common.service';
import { LocalStorageService } from './local-storage.service';
import { ShowErrorService } from './show-error.service';

@Injectable({
	providedIn: 'root',
})
export class LogoutService {
	URLConstants = URLConstants;
	callAPIConstants = callAPIConstants;

	constructor(public localstorage: LocalStorageService, private router: Router, private commonService: CommonService,
		private showErrorService: ShowErrorService) { }

	/*************************************************************
	@PURPOSE      : Logout
	@PARAMETERS   : NA
	@RETURN       : NA
	/*************************************************************/
	logout() {
		let Id;
		let Pass;
		let remember;
		if (this.localstorage.getToken('Id') && this.localstorage.getToken('Pass')) {
			Id = this.localstorage.getToken('Id');
			Pass = this.localstorage.getToken('Pass');
			remember = true;
		}
		this.commonService.callApi(this.callAPIConstants.AdminLogout, '', 'get', false, false, false).then((success) => {
			if (success.status === 1) {
				this.showErrorService.displayToastr('success', success.message);
				this.router.navigate([URLConstants.LOGIN]);
				this.localstorage.removeToken('firstname');
				this.localstorage.removeToken('role_permission');
				this.localstorage.removeToken('userId');
				this.localstorage.removeToken('accessToken');
				this.localstorage.removeToken('menuAlignment');
				this.localstorage.removeToken('lastname');
				this.localstorage.removeToken('photo');
				this.localstorage.removeToken('role');
				if (remember) {
					this.localstorage.setToken('Id', Id);
					this.localstorage.setToken('Pass', Pass);
				}
			}
		})

	}
	/*************************************************************/
}
