<div class="theme-setting-block" [ngClass]="{'open': isSettingOpen}">
    <a class="theme-setting-link" (click)="openSettingBox()">
        <em class="bx bx-cog bx-flip-horizontal" [ngClass]="{'bx-spin': !isSettingOpen}"></em>
    </a>
    <div class="d-flex align-items-center">
        <span class="light-icon icon mr-1 d-block"><em class="bx bx-sun"></em></span>
        <div class="custom-control custom-switch theme-switch">
            <input type="checkbox" class="custom-control-input" id="loginSwitchTheme" (click)="toggleTheme()">
            <label class="custom-control-label" for="loginSwitchTheme"></label>
        </div>
        <span class="dark-icon icon"><em class="bx bxs-sun"></em></span>
    </div>
</div>
<!--Start Login Wrapper-->
<div class="login-wrapper"
    style="background:url(assets/images/login-bg.jpg) no-repeat center center; background-size: cover;">
    <div class="login-body">
        <section class="login-container row m-0">
            <div class="col-xl-8 col-11">
                <div class="card bg-login-card">
                    <div class="row m-0">
                        <div class="col-md-6 col-12 pl-0 pr-0">
                            <div class="card rounded-left mb-0 p-4 d-flex justify-content-center h-100">
                                <div class="card-header">
                                    <div class="card-title text-center mb-0">
                                        <img src="assets/images/logo.png" style="margin-bottom:20px;" alt="GitFunded"
                                            title="GitFunded" width="220">
                                        <h3 class="mb-4 mt-2">Welcome</h3>
                                        <h4 class="mb-2 mt-2">Super Admin</h4><br>
                                    </div>
                                </div>
                                <div class="card-content">
                                    <div class="card-body">
                                        <form (ngSubmit)="loginForm.form.valid && onLogin(loginForm)"
                                            #loginForm="ngForm">
                                            <h6 class="mb-4 text-center">Sign in to your account
                                            </h6>
                                            <div class="form-group mb-2">
                                                <label>Email address<sup class="bx bxs-star danger"></sup></label>
                                                <input pattern="^\w+([\.\-_+]?\w+)*@\w+([\.\-_+]?\w+)*(\.\w{2,10})+$"
                                                    ngModel type="text" name="email" id="EmailAddress"
                                                    aria-describedby="emailHelp" placeholder="Enter Email Address"
                                                    class="form-control" #email="ngModel"
                                                    (keydown)="restrictKeyPressService.RestrictSpace($event)"
                                                    [ngClass]="{ 'is-invalid':(loginForm.submitted || email.touched) && email.invalid  }"
                                                    required>
                                                <div *ngIf="(loginForm.submitted || email.touched) && email.invalid"
                                                    class="invalid-feedback">
                                                    <div *ngIf="email.errors.required">Please enter email address </div>
                                                    <div *ngIf="email.errors.pattern">Please enter valid email address
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="form-group mb-2 position-relative">
                                                <label>Enter Password<sup class="bx bxs-star danger"></sup></label>
                                                <input [type]="passwordType ? 'text' : 'password'" ngModel
                                                    id="password-field" type="password" name="password"
                                                    class="form-control" #password="ngModel"
                                                    placeholder="Enter Password"
                                                    (keydown)="restrictKeyPressService.RestrictSpace($event)"
                                                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}" minlength="8"
                                                    maxlength="16"
                                                    [ngClass]="{ 'is-invalid': (loginForm.submitted || password.touched)  && password.invalid }"
                                                    required>
                                                <span [tooltip]="passwordType ? 'Hide Password' : 'Show Password'"
                                                    toggle="#password-field"
                                                    class="bx bx-hide field-icon toggle-password" [ngClass]="{
                                                    'bx-bx-hide': !passwordType,
                                                    'bx-show': passwordType
                                                  }" (click)="toggleFieldTextType()"></span>

                                                <div *ngIf="(loginForm.submitted || password.touched) && password.invalid"
                                                    class="invalid-feedback">
                                                    <div *ngIf="password.errors.required">
                                                        Please enter password
                                                    </div>
                                                    <div
                                                        *ngIf="password.errors.pattern || password.errors.minlength || password.errors.maxlength ">
                                                        Please enter valid password
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="form-group d-flex flex-md-row flex-column justify-content-between align-items-center mb-3">
                                                <div class="text-left">
                                                    <div class="custom-checkbox sm">
                                                        <label for="check1" class="mb-0">
                                                            <input type="checkbox" name="csscheckbox" id="check1"
                                                                autocomplete="off">
                                                            <span></span>Keep me logged in
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="text-right">
                                                    <a routerLink="/forgot-password" class="card-link">
                                                        Forgot password?
                                                    </a>
                                                </div>
                                            </div>
                                            <button class="btn btn-primary glow position-relative btn-block">
                                                Login<em class="icon-arrow bx bx-right-arrow-alt"></em>
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 d-md-block d-none text-center align-self-center p-7">
                            <div class="card-content">
                                <picture>
                                    <source srcset="assets/images/login.webp" type="image/webp">
                                    <source srcset="assets/images/login.png" type="image/png">
                                    <img class="img-fluid" src="assets/images/login.png" alt="login">
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>