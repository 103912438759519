<div class="main-content-area">
    <header id="header" class="">
        <div class="navigation-bar">
            <nav class="navbar d-flex navbar-expand bd-navbar fixed-top">
                <div class="mr-2 float-left d-flex align-items-center" (click)="openSidebar()">
                    <ul class="nav navbar-nav">
                        <li class="nav-item mobile-menu mr-auto">
                            <a class="nav-link nav-menu-main menu-toggle hidden-xs"><em class="bx bx-menu"></em></a>
                        </li>
                    </ul>
                    <ul class="horizontal-brand nav navbar-nav">
                        <li>
                            <a href="">
                                <img class="img-fluid" src="assets/images/logo.png" alt="branding logo">
                            </a>
                        </li>
                    </ul>
                </div>
                <ul class="navbar-nav flex-row ml-auto d-md-flex">
                    <li class="nav-item user-dropdown dropdown" dropdown>
                        <a class="nav-link dropdown-toggle dropdown-user-link" dropdownToggle type="button"
                            id="userDropdown" aria-controls="dropdown-animated">
                            <div class="user-nav d-sm-flex d-none">
                                <span class="user-name">{{firstname}} {{lastname}}</span>
                            </div>
                            <span class="profile-header"> <img class=""
                                    [src]="photo ? photo : 'assets/images/NoProfile.png'" alt="no img"
                                    (error)="img.src = 'assets/images/NoProfile.png'" #img></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" id="userDropdown" *dropdownMenu
                            class="dropdown-menu" role="menu" aria-labelledby="button-animated">
                            <a class="dropdown-item" [routerLink]="[URLConstants.MY_PROFILE]"><em
                                    class='bx bx-user mr-2'></em>{{ 'Header.PROFILE' | translate }}</a>
                            <a style="cursor: pointer;" (click)="openModal(template)" class="dropdown-item"><em
                                    class='bx bx-user mr-2'></em>{{ 'Header.CHANGEPASSWORD' | translate }}</a>

                            <a class="dropdown-item" style="cursor: pointer;" (click)="logout()"><em
                                    class='bx bx-log-out mr-2'></em>{{ 'Header.LOGOUT' | translate }}</a>
                        </div>
                        <ng-template #template>
                            <div class="modal-header">
                                <h4 class="modal-title pull-left">Change Password</h4>
                                <button type="button" class="close pull-right" aria-label="Close"
                                    (click)="modalRef.hide()">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form (ngSubmit)="changePassForm.form.valid && changePassword(changePassForm)"
                                    #changePassForm="ngForm">

                                    <div class="form-group mb-2 position-relative">
                                        <label>Old Password<sup class="bx bxs-star danger"></sup></label>
                                        <input [type]="passwordTypeOld ? 'text' : 'password'" ngModel
                                            id="password-field" type="password" name="oldPassword" class="form-control"
                                            #oldPassword="ngModel" placeholder="Old Password" minlength="8"
                                            maxlength="16"
                                            [ngClass]="{ 'is-invalid': (changePassForm.submitted || oldPassword.touched)  && oldPassword.invalid }"
                                            required (keydown)="restrictKeyPressService.RestrictSpace($event)"
                                            pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$">
                                        <span [tooltip]="passwordTypeOld ? 'Hide Password' : 'Show Password'"
                                            toggle="#password-field" class="bx bx-hide field-icon toggle-password"
                                            [ngClass]="{
                                    'bx-bx-hide': !passwordTypeOld,
                                    'bx-show': passwordTypeOld
                                          }" (click)="toggleFieldTextTypeOld()"></span>

                                        <div *ngIf="(changePassForm.submitted || oldPassword.touched) && oldPassword.invalid"
                                            class="invalid-feedback">
                                            <div *ngIf="oldPassword.errors.required">Please enter old password</div>
                                            <div
                                                *ngIf="oldPassword.errors.pattern || oldPassword.errors.minlength || oldPassword.errors.maxlength">
                                                Please enter valid password</div>
                                        </div>

                                    </div>
                                    <div class="form-group mb-2 position-relative">
                                        <label>New Password<sup class="bx bxs-star danger"></sup></label>
                                        <input [type]="passwordTypeNew ? 'text' : 'password'" ngModel
                                            id="password-field" type="password" name="newPassword" class="form-control"
                                            #newPassword="ngModel" placeholder="New Password" minlength="8"
                                            maxlength="16"
                                            [ngClass]="{ 'is-invalid': (changePassForm.submitted || newPassword.touched)  && newPassword.invalid }"
                                            required (keydown)="restrictKeyPressService.RestrictSpace($event)"
                                            pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$">
                                        <span [tooltip]="passwordTypeNew ? 'Hide Password' : 'Show Password'"
                                            toggle="#password-field" class="bx bx-hide field-icon toggle-password"
                                            [ngClass]="{
                                    'bx-bx-hide': !passwordTypeNew,
                                    'bx-show': passwordTypeNew
                                          }" (click)="toggleFieldTextTypeNew()"></span>

                                        <div *ngIf="(changePassForm.submitted || newPassword.touched) && newPassword.invalid"
                                            class="invalid-feedback">
                                            <div *ngIf="newPassword.errors.required">Please enter new password</div>
                                            <div
                                                *ngIf="newPassword.errors.pattern || newPassword.errors.minlength || newPassword.errors.maxlength">
                                                Password should contain atleast a
                                                special character (@, $, !, %, *, ?, & or #), a capital letter and a
                                                number and length should be between 8-16</div>
                                        </div>
                                    </div>
                                    <button class="btn btn-primary glow position-relative btn-block mt-3">
                                        Change Password
                                    </button>
                                </form>
                            </div>
                        </ng-template>
                    </li>

                    <li class="nav-item user-dropdown dropdown  theme-setting-block-nav-link" dropdown>
                        <a class="nav-link dropdown-toggle dropdown-user-link theme-setting-link" dropdownToggle
                            type="button" id="headerDropdown" aria-controls="dropdown-animated">
                            <em class="bx bx-cog bx-flip-horizontal bx-spin"></em>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right theme-setting-block" id="headerDropdown"
                            *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-animated"
                            (click)="$event.stopPropagation()">
                            <div class="d-flex align-items-center dropdown-item">
                                <span class="light-icon icon mr-1 d-block"><em class="bx bx-sun"></em></span>
                                <div class="custom-control custom-switch theme-switch">
                                    <input type="checkbox" class="custom-control-input" id="customSwitchHeaderTheme"
                                        (click)="toggleTheme()">
                                    <label class="custom-control-label" for="customSwitchHeaderTheme"></label>
                                </div>
                                <span class="dark-icon icon"><em class="bx bxs-sun"></em></span>
                            </div>
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
    </header>
</div>