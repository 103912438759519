
export const URLConstants = {
    /***** Unauthorized Pages *****/
    LOGIN: '/login',
    FORGOT_PASS: '/forgot-password',
    RESET_PASS: '/reset-password',
    SIGNUP: '/register',
    DASHBOARD: '/dashboard',
    OTP: '/enter-otp/',

    /***** Authorized Pages *****/
    CHANGE_PASS: '/change-password',
    MY_PROFILE: '/my-profile',
    USERS: '/users',
    MASTER: '/master',

    /***** Setting Pages *****/
    SETTING_GENERAL: '/setting/setting-general',
    SETTING_SOCIALMEDIA: '/setting/setting-socialmedia',
    SETTING_PAYMENTGATEWAY: '/setting/setting-paymentgateway',
    SETTING_SMTPSMS: '/setting/setting-smtpsms',
    SETTING_EMAILNOTIFICATION: '/setting/setting-emailnotification',

    // Email Templates
    EMAIL_TEMPLATE_list: '/email-template',
    EMAIL_TEMPLATE_detail: '/email-template/edit-details/',
    EMAIL_TEMPLATE_ADDNEWTEMPLATE: '/email-template/addNewTemplate',

    // content Management
    CONTENT_PAGE: '/content/content-page',
    ADD_CONTENT_PAGE: '/content/add-content-page',
    EDIT_VIEW_STATIC_PAGE: '/content/edit-view-content-page/',
    VIEW_STATIC_DETAILS: '/content/view-static-page-details',

    BLOGS_CATEGORY: '/content/blog-category',
    BLOGS: '/content/blog-list',
    BLOG_ADD: '/content/blog-add',
    BLOG_EDIT: '/content/blog-edit/',
    BLOG_PREVIEW: '/content/view-blog-details/',

    FAQS: '/content/faq',

    // user list
    USERS_DETAIL: '/users/view-details/',
    ADDUSER: '/users/add-user/',
    UPDATEUSER: '/users/update-user/',
    // manage roles
    MANAGE_ROLES: '/manage-roles',
    CREATE_ROLE: '/manage-roles/create-role',
    EDIT_ROLE: '/manage-roles/edit-details/',

    // manage sub admin
    MANAGE_SUB_ADMIN: '/manage-sub-admin',
    ADD_SUB_ADMIN: '/manage-sub-admin/add-sub-admin',
    EDIT_SUB_ADMIN: '/manage-sub-admin/update-sub-admin/',

    /**********Master*********/
    INDUSTRY: '/master/industry',
    SUBINDUSTRY: '/master/sub-industry',
    SKILLS: '/master/skills',
    LANGUAGE: '/master/language',

    //Pages
    PagesList: 'my-pages/list',
    PagesDetail: 'my-pages/view-details',

    FeedVideos: 'feed-videos',
    FeedPages: 'feed-pages',
    FeedComments: 'feed-comments',
    DisputeRequests: '/dispute-request/list',
    DisputeDetail: '/dispute-request/detail',
    JobDetail: 'dispute-request/job-detail',

    InvestorRequest: 'investor-request',
    IdentityRequest: 'identity-request',

    //Subscriptions                
    PACKAGES: 'subscriptions',

    // Reports
    PAYMENT_TRN: 'reports/payment-trn',
    CREDIT_TRN: 'reports/credit-trn'
};

export const Role_Category = {
    adminUserAccess: 'Manage Sub Admin Users',
    cmsPagesAccess: 'Manage Static Content',
    commentsAccess: 'Manage Feed Comments',
    disputeAccess: 'Dispute Requests',
    identityRequestsAccess: 'Manage Verification Requests',
    // masterDataAccess: 'Manage Master Data',
    pageAccess: 'Manage Feed Pages',
    pagesAccess: 'Manage Pages',
    reportsAccess: 'Reports',
    rolesAccess: 'Manage Role',
    userAccess: 'Manage Users',
    videosAccess: 'Manage Feed Videos',
}


export const creditSubType = {
    promoteVideo: 'promote_video',
    promotePage: 'promote_page',
    promoteJob: 'promote_job',
    promotionCost: 'additional_promotion_cost',
    applyJob: 'apply_for_job'
}
