<div class="modal-wrap">
    <h5>Add Comment</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
    <hr>
    <form class="mark-as-form" #reportForm="ngForm">
        <div class="form-group">
            <label>Comment<sup class="bx bxs-star danger"></sup></label>
            <textarea class="w-100" placeholder="Type your comments here." name="reviewComment"
                [(ngModel)]="comment.reviewComment" #reviewComment="ngModel" required></textarea>
            <div class="error-block text-left" *ngIf="((submitted || reviewComment.touched) && reviewComment.invalid)">
                Enter Comment
            </div>
        </div>
        <div class="form-group" *ngIf="subtype == 'accreditation' && type == 'Accepted'">
            <label>Expiry Date</label>
            <input type="text" placeholder="Expiry Date" class="w-100 form-control" name="verificationExpiryDate"
            [(ngModel)]="comment.verificationExpiryDate" #verificationExpiryDate="ngModel" bsDatepicker>
        </div>
        <div class="btn-wrap mark-btn mt-6 text-center">
            <button class="btn btn-primary btn-lg" type="button"
                (click)="reportData(reportForm,comment)">Submit</button>
        </div>
    </form>
</div>