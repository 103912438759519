<div class="modal-wrap accridation-req-modal">
    <div class="d-flex align-items-center modal-top-bar">
        <div class="video-image mr-2">
            <img class="img-fluid" alt="image"
                [src]="(reportData.identityInfo?.photo) ? imgUrl + (reportData.identityInfo?.photo) :  'assets/images/NoProfile.png'"
                (error)="img1.src = 'assets/images/NoProfile.png'" width="50" height="50" #img1>
        </div>
        <div class="text-bar">
            <p class="user-name">{{reportData.identityInfo?.name}}</p>
            <p class="time">Submitted documents on {{reportData.createdAt | date:'shortDate'}} at {{reportData.createdAt
                | date
                :'shortTime'}}
            </p>
        </div>
    </div>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
    <hr>
    <form class="accridation-req-form">
        <div class="d-flex">
            <div class="video-image mr-2">
                <img class="img-fluid" alt="image"
                    [src]="(reportData.reviewedBy?.photo) ? imgUrl + (reportData.reviewedBy?.photo) :  'assets/images/NoProfile.png'"
                    (error)="img1.src = 'assets/images/NoProfile.png'" width="50" height="50" #img1>
            </div>
            <div class="info-wrap">
                <p class="user-name">{{reportData.reviewedBy?.firstname}} {{reportData.reviewedBy?.lastname}}</p>
                <p class="mail">{{reportData.reviewedBy?.emailId}}</p>
                <p class="time">Reviewed and {{(reportData.status == 'Accepted') ? 'accepted' : 'rejected'}} the request
                    on
                    {{reportData.reviewedOn | date:'shortDate'}} at {{reportData.reviewedOn | date
                    :'shortTime'}} </p>
                <p *ngIf = "subtype == 'accreditation' && reportData.status == 'Accepted'">Expiry Date: {{reportData.identityId.accreditationExpiryDate | date:'shortDate'}}</p>    
            </div>
        </div>
        <p class="mt-3">{{reportData.reviewComment}}</p>
        <div class="documents-types">
            <h6 class="fw-sb">Attachments</h6>
            <div class="d-flex flex-wrap" *ngIf="reportData.attachments?.length>0">
                <ng-container *ngFor="let i of reportData.attachments">
                    <p class="d-flex documents-types-single align-items-center" style="cursor: pointer;"
                        (click)="gotoURL(i.path)"><em class="bx bx-file-blank pr-2"></em>{{i.path}}</p>
                </ng-container>
            </div>
            <div class="d-flex flex-wrap" *ngIf="reportData.attachments?.length==0">
                <p>No attachments Found</p>
            </div>
        </div>
    </form>
</div>