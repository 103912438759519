import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ThemeService } from '../../common/service/theme/theme.service';
import { ShowErrorService } from '../../common/service/show-error.service';

// constants
import { callAPIConstants } from '../../common/constants/callAPI-constants';
import { URLConstants } from '../../common/constants/routerLink-constants';

// service
import { CommonService } from '../../common/service/common.service';
import { LocalStorageService } from '../../common/service/local-storage.service';
import { environment } from 'src/environments/environment';
import { RestrictKeyPressService } from 'src/app/common/service/restrict-key-press.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: []
})
export class LoginComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  public isSettingOpen = false;
  passwordType: boolean;
  public user: any = {};
  uploadImageUrl = environment.uploadImgUrl;
  public submitted: boolean = false;


  constructor(private themeService: ThemeService, private router: Router,
    private localStorageService: LocalStorageService, public restrictKeyPressService: RestrictKeyPressService, private commonService: CommonService, private showErrorService: ShowErrorService
  ) { }

  ngOnInit(): void {
    var isDark = this.localStorageService.getToken('theme') == "Dark" ? true : false;
    this.themeService.switchThemeAfterLogin(isDark);
    if (isDark)
      document.getElementById("loginSwitchTheme").setAttribute('checked', '');
  }

  onLogin(form) {
    this.submitted = true;
    if (form.value) {
      this.user = {
        authName: form.value.email,
        password: form.value.password
      }
      this.commonService.callApi(this.callAPIConstants.AdminLogin, this.user, 'post', true, false).then((success) => {
        if (success.status === 1 && !success.data) {
          this.showErrorService.displayToastr('error', success.message);
          return
        } else if (success.status === 1 && success.data) {
          this.localStorageService.setToken('accessToken', success.access_token);
          this.localStorageService.setToken('firstname', success.data.firstname);
          this.localStorageService.setToken('lastname', success.data.lastname);
          this.localStorageService.setToken('userId', success.data._id)
          if (success.data.photo != undefined) {
            this.localStorageService.setToken('photo', this.uploadImageUrl + success.data.photo);
          }
          if (this.localStorageService.getToken('theme') == null) {
            this.localStorageService.setToken('theme', success.data.theme);
          } else {
            this.localStorageService.setToken('theme', this.localStorageService.getToken('theme'));
          }
          this.localStorageService.setToken('menuAlignment', success.data.menuAlignment);
          this.localStorageService.setToken('role', success.data.roleName);
          this.localStorageService.setToken('role_permission', this.commonService.encrypt(success.data.staticRolePermission));
          var isDark = success.data.theme == "Dark" ? true : false;
          this.themeService.switchThemeAfterLogin(isDark);
          this.showErrorService.displayToastr('success', success.message);
          this.router.navigate([this.URLConstants.DASHBOARD]);
        } else {
          this.showErrorService.displayToastr('error', success.message);
        }
      });
    }


  }
  /*************************************************************/
  openSettingBox() {
    this.isSettingOpen = !this.isSettingOpen;
  }
  toggleFieldTextType() {
    this.passwordType = !this.passwordType;
  }
  toggleTheme() {
    var theme = this.themeService.switchTheme();
    this.localStorageService.setToken('theme', theme);
  }
}
